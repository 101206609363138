const PROTOCOL = process.env.REACT_APP_PROTOCOL;
const SOCKET_PROTOCOL = process.env.REACT_APP_SOCKET_PROTOCOL;
const SERVER = process.env.REACT_APP_SERVER_HOST || "localhost";
const SERVER_PORT = process.env.REACT_APP_SERVER_PORT || 5000;
const NETWORK_NAME = 'scodes';

const URL = `${PROTOCOL}://${SERVER}:${SERVER_PORT}`;

const TRIAL_ACTIONS = ['START',
    'STOP',
    'COMPLETE',
    'WITHDRAW'];
const DATA_TYPES = ['TEMPERATURE',
    'PRESSURE',
    'HEART_RATE',
    'GLYCEMIA',
    'SLEEP',
    'EXCERCISE'
];
const PURPOSES = ['PURPOSE_1',
    'PURPOSE_2',
    'PURPOSE_3'
];

const getAssets = async (cardName, type) => {
    let res = await fetch(`${URL}/assets`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            cardName: cardName,
            type: type,
        })
    });
    return res = await res.json();
}

const getAssetsByStatus = async (cardName, type, status) => {
    let res = await fetch(`${URL}/${type.toLowerCase()}/status`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            cardName: cardName,
            status: status
        })
    });
    return res = await res.json();
}

const getParticipants = async (cardName, type) => {
    let res = await fetch(`${URL}/participant/all`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            cardName: cardName,
            type: type,
        })
    });
    return res = await res.json();
}

const signIn = async (email, password) => {
    try {
        let res = await fetch(`${URL}/signin`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                cardName: `${password}@${NETWORK_NAME}`,
                email: email,
            })
        });
        return res = await res.json();
    } catch (error) {
        console.log(error)
    }
}

const getParticipantsByIds = async (ids) => {
    try {
        let res = await fetch(`${URL}/participants`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ids: ids,
            })
        });
        return res = await res.json();
    } catch (error) {
        console.log(error)
    }
}

const createParticipant = async (state) => {
    let res = await fetch(`${URL}/participant/create`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "type": state.role,
            "name": state.name,
            "surname": state.surname,
            "email": state.email,
            "company": state.company
        })
    });
    return res;
}

const createTrial = async (cardName, state) => {
    let res = await fetch(`${URL}/trial/new`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "cardName": cardName,
            "trialData": {
                "trialId": state.trialId,
                "duration": state.duration,
                "description": state.description,
                "supervisors": state.supervisors,
                "phases": state.phases,
            }
        })
    });
    return res;
}

const issueContract = async (cardName, state) => {
    let res = await fetch(`${URL}/contract/issue`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "cardName": cardName,
            "contractData": {
                "trialId": state.trialId,
                "demander": state.demander,
                "recipient": state.recipient,
            }
        })
    });
    return res;
}

const updateAssetStatus = async (cardName, type, action, id) => {
    let res = await fetch(`${URL}/${type}/${action}`, {
        method: 'put',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "cardName": cardName,
            "id": id
        })
    });
    return res;
}

const listenEvents = async (cardName) => {
    let res = await fetch(`${URL}/events`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "cardName": cardName,
        })
    });
    return res;
}

const getAllEvents = async (cardName, time) => {
    let res = await fetch(`${URL}/events/all`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "cardName": cardName,
            "time": time
        })
    });
    return res.json();
}

const getLastLogout = async (user) => {
    let res = await fetch(`${URL}/logout`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "user": user,
        })
    });
    return res.json();
}

const getTransactions = async (cardName) => {
    let res = await fetch(`${URL}/transactions`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "cardName": cardName,
        })
    });
    return res.json();
}

const getPatientsData = async (patients, supervised) => {
    let res = await fetch(`${URL}/redcap/export`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "patients": patients,
            "supervised": supervised,
        })
    });
    return res.json();
}

const addRecords = async (data) => {
    let res = await fetch(`${URL}/redcap/import`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "data": data
        })
    });
    return res;
}

const generateId = async () => {
    let res = await fetch(`${URL}/redcap/id`, {
        method: 'get',
        headers: { 'Content-Type': 'application/json' },
    });
    return res.json();
}

const getSupervised = async (cardName, status, demander) => {
    let res = await fetch(`${URL}/contract/demander`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "cardName": cardName,
            "status": status,
            "demander": demander
        })
    });
    return res.json();
}

const getObserved = async (cardName, observer) => {
    let res = await fetch(`${URL}/trials/observed`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "cardName": cardName,
            "observer": observer
        })
    });
    return res.json();
}

const getAcceptedData = async (ids, participants) => {
    let res = await fetch(`${URL}/redcap/datasets`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "ids": ids,
            "participants": participants
        })
    });
    return res.json();
}

export {
    PROTOCOL,
    SOCKET_PROTOCOL,
    SERVER,
    SERVER_PORT,
    TRIAL_ACTIONS,
    DATA_TYPES,
    PURPOSES,
    createParticipant,
    createTrial,
    getAssets,
    getAssetsByStatus,
    signIn,
    getParticipants,
    issueContract,
    updateAssetStatus,
    listenEvents,
    getAllEvents,
    getLastLogout,
    getParticipantsByIds,
    getTransactions,
    getPatientsData,
    addRecords,
    generateId,
    getSupervised,
    getObserved,
    getAcceptedData
}