import React from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Col,
  Row,
} from 'reactstrap';
import './BioData.css'
import Stats from "components/Stats/Stats.jsx";
import PropTypes from "prop-types";

class BioData extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Card className={'card-stats ' + (this.props.plain && 'card-plain') || ''}>
        <CardHeader>
          <CardTitle tag="h4">Biometric Data</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs={12} md={4}>
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs={5} md={4}>
                      <div className="icon-big text-center">
                        <i className="fa fa-heartbeat text-primary" />
                      </div>
                    </Col>
                    <Col xs={7} md={8}>
                      <div className="numbers">
                        <p className="card-category">Heart Rate<br /><small className="text-primary no-transform">bpm</small></p>
                        <CardTitle tag="p">{data.heart_rate !== undefined 
                        ? data.heart_rate 
                        : <img className='small-footer' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                        }</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Stats>
                    {[
                      {
                        i: "fas fa-sync-alt",
                        t: "Update"
                      }
                    ]}
                  </Stats>
                </CardFooter>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs={5} md={4}>
                      <div className="icon-big text-center">
                        <i className="fa fa-stethoscope text-primary" />
                      </div>
                    </Col>
                    <Col xs={7} md={8}>
                      <div className="numbers">
                        <p className="card-category">Pressure<br /><small className="text-primary no-transform">mmHg</small></p>
                        <CardTitle tag="p">{data.pressure !== undefined 
                        ? data.pressure 
                        : <img className='small-footer' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                        }</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Stats>
                    {[
                      {
                        i: "fas fa-sync-alt",
                        t: "Update"
                      }
                    ]}
                  </Stats>
                </CardFooter>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs={5} md={4}>
                      <div className="icon-big text-center">
                        <i className="fa fa-thermometer-half text-primary" />
                      </div>
                    </Col>
                    <Col xs={7} md={8}>
                      <div className="numbers">
                        <p className="card-category">Temperature<br /><small className="text-primary no-transform">Celsius</small></p>
                        <CardTitle tag="p">{data.temperature !== undefined 
                        ? data.temperature 
                        : <img className='small-footer' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                        }</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Stats>
                    {[
                      {
                        i: "fas fa-sync-alt",
                        t: "Update"
                      }
                    ]}
                  </Stats>
                </CardFooter>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs={5} md={4}>
                      <div className="icon-big text-center">
                        <i className="fa fa-syringe text-primary" />
                      </div>
                    </Col>
                    <Col xs={7} md={8}>
                      <div className="numbers">
                        <p className="card-category">Glycemia<br /><small className="text-primary no-transform">mg/dl</small></p>
                        <CardTitle tag="p">{data.glycemia !== undefined 
                        ? data.glycemia 
                        : <img className='small-footer' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                        }</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Stats>
                    {[
                      {
                        i: "fas fa-sync-alt",
                        t: "Update"
                      }
                    ]}
                  </Stats>
                </CardFooter>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs={5} md={4}>
                      <div className="icon-big text-center">
                        <i className="fa fa-procedures text-primary" />
                      </div>
                    </Col>
                    <Col xs={7} md={8}>
                      <div className="numbers">
                        <p className="card-category">Sleep Monitor<br /><small className="text-primary no-transform">h</small></p>
                        <CardTitle tag="p">{data.sleep !== undefined 
                        ? data.sleep 
                        : <img className='small-footer' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                        }</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Stats>
                    {[
                      {
                        i: "fas fa-sync-alt",
                        t: "Update"
                      }
                    ]}
                  </Stats>
                </CardFooter>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col xs={5} md={4}>
                      <div className="icon-big text-center">
                        <i className="fa fa-fire text-primary" />
                      </div>
                    </Col>
                    <Col xs={7} md={8}>
                      <div className="numbers">
                        <p className="card-category">Exercise<br /><small className="text-primary no-transform">kcal</small></p>
                        <CardTitle tag="p">{data.exercise !== undefined 
                        ? data.exercise 
                        : <img className='small-footer' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                        }</CardTitle>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <Stats>
                    {[
                      {
                        i: "fas fa-sync-alt",
                        t: "Update"
                      }
                    ]}
                  </Stats>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }
}

BioData.propTypes = {
  plain: PropTypes.bool
};

export default BioData;