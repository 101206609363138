import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// For the chart
import { Line } from 'react-chartjs-2';
import Stats from "components/Stats/Stats.jsx";
import { bioDataset } from "variables/charts.jsx";

class BioDataset extends React.Component {
  render() {
    const { heart_rate, pressure, exercise, glycemia, sleep, temperature } = this.props.dataset;
    return (
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader>
              <CardTitle><h4>Biometric Dataset</h4></CardTitle>
              <CardBody>
                <p>Heart rate<small className="text-primary no-transform"> bpm</small></p>
                <Line
                  redraw={true}
                  data={bioDataset.data(heart_rate, "green")}
                  options={bioDataset.options}
                  width={600}
                  height={200}
                />
                <p>Pressure<small className="text-primary no-transform"> mmHg</small></p>
                <Line
                  redraw={true}
                  data={bioDataset.data(pressure, "azure")}
                  options={bioDataset.options}
                  width={600}
                  height={200}
                />
                <p>Temperature<small className="text-primary no-transform"> °C</small></p>
                <Line
                  redraw={true}
                  data={bioDataset.data(temperature, "yellow")}
                  options={bioDataset.options}
                  width={600}
                  height={200}
                />
                <p>Glycemia<small className="text-primary no-transform"> mg/dl</small></p>
                <Line
                  redraw={true}
                  data={bioDataset.data(glycemia, "red")}
                  options={bioDataset.options}
                  width={600}
                  height={200}
                />
                <p>Sleep<small className="text-primary no-transform"> h</small></p>
                <Line
                  redraw={true}
                  data={bioDataset.data(sleep, "blue")}
                  options={bioDataset.options}
                  width={600}
                  height={200}
                />
                <p>Exercise<small className="text-primary no-transform"> kcal</small></p>
                <Line
                  redraw={true}
                  data={bioDataset.data(exercise, "grey")}
                  options={bioDataset.options}
                  width={600}
                  height={200}
                />
              </CardBody>
              <CardFooter>
                <hr />
                <Stats>
                  {[
                    {
                      i: "fas fa-history",
                      t: `Updated`
                    }
                  ]}
                </Stats>
              </CardFooter>
            </CardHeader>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default BioDataset;