import React from "react";
import { FormGroup, Label, Input, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, FormFeedback } from "reactstrap";

import Button from "components/CustomButton/CustomButton.jsx";
import './NewContract.css'

import * as api from '../../api/api.js'

export default class NewContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      error: false,
      demander: this.props.user.email,
      trialId: '',
      recipient: ''
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = async () => {
    if (this.checkFields()) {
      this.setState({ fetching: true });
      await api.issueContract(this.props.user.cardName, this.state);
      this.setState({ fetching: false });
      this.props.update();
    }
  }

  checkFields() {
    const { trialId, recipient } = this.state;
    if (recipient === '' || trialId === '') {
      this.setState({ error: true });
      return false;
    }

    return true;
  }

  render() {
    const { isOpen, toggle, patients, trials } = this.props;
    return (
      <div className="content">
        <Modal
          isOpen={isOpen}
          toggle={toggle}
        >
          <ModalHeader toggle={toggle}>New Contract</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={8}>
                <FormGroup>
                  <Label>Demander</Label>
                  <Input type="email" name="email" value={this.props.user.email} disabled>
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={4}>
                <FormGroup>
                  <Label>Trial</Label>
                  <Input type="select" name="trialId" onChange={this.handleChange} invalid={this.state.error && !this.state.trialId}>
                    <option hidden></option>
                    {trials.map((el, index) => {
                      return <option key={index}>{el.trialId}</option>
                    })}
                  </Input>
                  {this.state.error &&
                    <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                  }
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup>
                  <Label>Recipient</Label>
                  <Input type="select" name="recipient" onChange={this.handleChange} invalid={this.state.error && !this.state.recipient}>
                    <option hidden></option>
                    {patients.map((el, index) => {
                      return <option key={index}>{el.email}</option>
                    })}
                  </Input>
                  {this.state.error &&
                    <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                  }
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {this.state.fetching
              ? <Button disabled color="text-gray"><img className='spinner' src={require('../../assets/img/spinner.gif')} alt="spinner" /></Button>
              : <Button color="success" onClick={this.handleSubmit}>Submit</Button>
            }
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}