/*
 * Define the routes for Sign In/Register directly here 
 */
import React from 'react';
import {
  Button,
  ButtonGroup,
  Container,
  Navbar,
  NavbarBrand,
  Nav,
} from 'reactstrap';
import './PublicHeader.css'

class PublicHeader extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    const { toggleSignin, toggleRegister } = this.props;
    return (
      <Navbar
        color="dark"
        expand="true"
        className="navbar-absolute"
      >
        <Container fluid>
          {/* <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref="sidebarToggle"
                className="navbar-toggler"
                onClick={() => this.toggle()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <div>
            </div>
          </div> */}
          <div>
            <img className="logo-img" src={require('../../assets/img/logo-color.svg')} alt="logo" />
            <NavbarBrand>SCoDES</NavbarBrand>
          </div>
          {/* <Collapse isOpen={this.state.isOpen} navbar> */}
          <Nav className="ml-auto" navbar>
            <ButtonGroup>
              <Button color="primary" onClick={toggleSignin}>
                <span className="d-sm-none d-xs-none"><i className="nc-icon nc-lock-circle-open access" /></span>
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">SignIn</span>
              </Button>
              <Button color="warning" onClick={toggleRegister}>
                <span className="d-sm-none d-xs-none"><i className="nc-icon nc-badge access" /></span>
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">Register</span>
              </Button>
            </ButtonGroup>

          </Nav>
          {/* </Collapse> */}
        </Container>
      </Navbar>
    );
  }
}

export default PublicHeader;