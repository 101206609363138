import React from 'react';
import './NoContent.css'

class NoContent extends React.Component {
    render() {
        return (
            <div className="no-content">
                <div>
                    <img src={require('../../assets/img/logo-bw.svg')} alt="logo-bw" />
                    <p>No content yet!</p>
                    <hr/>
                </div>
            </div>
        )
    }
}

export default NoContent;