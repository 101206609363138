import React from 'react';

import PatientCard from "../../components/PatientCard/PatientCard"
import NoContent from "../../components/NoContent/NoContent"
import Loading from "../../components/Loading/Loading"

import * as api from '../../api/api.js'

export default class MedicalData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      patients: {},
      contracts: [],
      data: [],
    }
  }


  componentDidMount = async () => {
    await this.updateData();
  }

  updateData = async () => {
    this.setState({ fetching: true });
    const full_id = `resource:org.hevs.scodes.clinical.Doctor#${this.props.user.email}`;
    const supervised = await api.getSupervised(this.props.user.cardName, 'ACCEPTED', full_id);
    const patients = this.getAvailablePatients(supervised);
    const data = await api.getPatientsData(patients, supervised);
    this.setState({
      fetching: false,
      contracts: supervised,
      patients: patients,
      data: data,
    });
  }

  getAvailablePatients(contracts) {
    let patients = {};
    for (let contract of contracts) {
      let trial_id = contract.trial.split('#')[1];
      let pat_id = contract.recipient.split('#')[1];
      if (patients[pat_id]) {
        patients[pat_id].push(trial_id)
      } else {
        patients[pat_id] = [trial_id];
      }
    }
    return patients;
  }

  render() {
    if (!this.state.fetching) {
      if (this.state.contracts.length > 0) {
        return (
          <div className="content">
            {this.state.data.map((entry, index) => {
              return <PatientCard key={index} data={entry} />
            })}
          </div>
        )
      } else return <NoContent />
    } else return <Loading />
  }
}
