import App from '../App';

var indexRoutes = [
    {
        path: "/",
        name: "Home",
        component: App
    }
];

export default indexRoutes;
