import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
} from "reactstrap";
// react plugin used to create charts
import CardAuthor from "components/CardElements/CardAuthor.jsx";
import BioData from "components/BioData/BioData.jsx"
import UsersTable from "components/UsersTable/UsersTable.jsx"

import './PatientDashboard.css'

//TODO: remove
import blank from "assets/img/blank-avatar.png";

import * as api from '../../api/api.js'
import * as utils from '../../utils/helpers'

class PatientDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      trialData: {},
      contractData: {},
      patientData: {},
      biodata: {},
      supervisors: [],
      observers: [],
      accepted: 0,
      revoked: 0,
      started: 0,
      completed: 0,
      stopped: 0,
      withdrawn: 0,
    }
  }

  async componentDidMount() {
    this.setState({ fetching: true });
    const patientData = await api.getParticipants(this.props.user.cardName, 'Patient');
    const trialData = await api.getAssets(this.props.user.cardName, 'Trial');
    const contractData = await api.getAssets(this.props.user.cardName, 'Contract');
    const ids = this.getActiveTrials(contractData);
    const sup_ids = this.getSupervisors(ids, trialData);
    const obs_ids = this.getObservers(ids, trialData);
    const supervisors = await api.getParticipantsByIds(sup_ids);
    const observers = await api.getParticipantsByIds(obs_ids);
    const biodata = utils.generateRndBiodata();
    this.countTrialsByStatus(trialData);
    this.countContractsByStatus(contractData);
    this.setState({
      fetching: false,
      trialData: trialData,
      contractData: contractData,
      patientData: patientData,
      supervisors: supervisors,
      observers: observers,
      biodata: biodata
    });
  }

  // Moved logic to count trials and contracts on client side (less queries)
  countTrialsByStatus(trials) {
    for (let trial of trials) {
      const field = trial.status.toLowerCase();
      this.setState({ [field]: this.state[field] += 1 });
    };
  }

  countContractsByStatus(contracts) {
    for (let contract of contracts) {
      const field = contract.status.toLowerCase();
      this.setState({ [field]: this.state[field] += 1 });
    };
  }

  getActiveTrials(contracts) {
    let ids = [];
    for (let contract of contracts) {
      if (contract.status === 'ACCEPTED') ids.push(contract.trial.split('#')[1])
    }
    return ids;
  }

  getSupervisors(ids, trials) {
    let supervisors = [];
    for (let trial of trials) {
      if (ids.includes(trial.trialId)) {
        supervisors = supervisors.concat(trial.supervisors)
      }
    }
    return [...new Set(supervisors)]; // to eliminate duplicates
  }

  getObservers(ids, trials) {
    let observers = [];
    for (let trial of trials) {
      if (ids.includes(trial.trialId)) {
        for (let phase of trial.phases) {
          observers = observers.concat(phase.observers);
        }
      }
    }
    return [...new Set(observers)]; // to eliminate duplicates
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col md={4} xs={12}>
            <Card className="card-user">
              <div className="image">
                {/* <img src={damirBosnjak} alt="..." /> */}
              </div>
              <CardBody>
                <CardAuthor
                  avatar={blank}
                  title={`${this.props.user.name} ${this.props.user.surname}`}
                  description={this.props.user.email}
                />
              </CardBody>
              <CardFooter>
                <hr />
                <div className="button-container">
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={4} className="ml-auto">
                      <h5>
                        {this.state.fetching
                          ? <img className='small-footer' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                          : this.state.contractData.length || '-'}
                        <br />
                        <small>Contracts</small>
                      </h5>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={4} className="mr-auto ml-auto">
                      <h5>
                        {this.state.fetching
                          ? <img className='small-footer' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                          : this.state.accepted || '-'}
                        <br />
                        <small>Accepted</small>
                      </h5>
                    </Col>
                    <Col lg={4} className="mr-auto">
                      <h5>
                        {this.state.fetching
                          ? <img className='small-footer' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                          : this.state.revoked || '-'}
                        <br />
                        <small>Revoked</small>
                      </h5>
                    </Col>
                  </Row>
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} md={8}>
            <BioData data={this.state.biodata} />
          </Col>
        </Row>
        {!this.state.fetching
          ? <div>
            <UsersTable users={this.state.supervisors} title="Investigators" thead={["Name", "Surname", "Email", "Company"]} />
            <UsersTable users={this.state.observers} title="Observers" thead={["Name", "Surname", "Email", "Company"]} />
          </div>
          : <div className="container centered pt-5 pb-4">
            <img className='medium' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
          </div>
        }
      </div>
    );
  }
}

export default PatientDashboard;