import React from "react";
import PublicHeader from '../../components/PublicHeader/PublicHeader';
import SignIn from '../../views/SignIn/SignIn';
import Register from '../../views/Register/Register';
import Landing from '../../views/Landing/Landing';
import Footer from '../../components/Footer/Footer';
import { Container } from 'reactstrap';
import './Public.css';
// import CustomJumbo from '../../components/CustomJumbo/CustomJumbo';

class Public extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signin: false,
      register: false
    }
  }

  toggleSignin() {
    this.setState({
      signin: !this.state.signin
    })
  }

  toggleRegister() {
    this.setState({
      register: !this.state.register
    })
  }

  render() {
    const { logIn, loadUser, toggleLoading } = this.props;
    return (
      <Container>
        <div className="fixed-top">
          <PublicHeader
            toggleSignin={this.toggleSignin.bind(this)}
            toggleRegister={this.toggleRegister.bind(this)} />
        </div>
        {/* <div className="j-container">
          <CustomJumbo />
        </div> */}
        <div>
          <Landing />
        </div>
        <SignIn
          {...this.props}
          logIn={logIn}
          isOpen={this.state.signin}
          toggleSignin={this.toggleSignin.bind(this)}
          loadUser={loadUser}
          togglLoading={toggleLoading}
        />
        <Register {...this.props}
          logIn={logIn}
          isOpen={this.state.register}
          toggleRegister={this.toggleRegister.bind(this)}
          loadUser={loadUser}
          togglLoading={toggleLoading}
        />
        <Footer />
      </Container>
    );
  }
}

export default Public;