import React from 'react';

import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";

import FormInputs from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import InfoModal from "components/InfoModal/InfoModal.jsx";
import './Register.css'

import * as api from '../../api/api.js'

export default class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      fetching: false,
      infoModal: false,
      name: '',
      surname: '',
      email: '',
      role: '',
      company: ''
    }
    this.toggleInfoModal = this.toggleInfoModal.bind(this);
  }

  toggleInfoModal() {
    this.setState({ infoModal: !this.state.infoModal })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmitRegister = async () => {
    this.setState({ fetching: true });
    const create_res = await api.createParticipant(this.state);
    if (create_res.status === 200) {
      let res = await api.signIn(this.state.email, this.state.email.split('@')[0]);
      if (res) {
        this.props.loadUser(res);
      } else {
        this.setState({ fetching: false, error: true });
      }
    } else {
      this.setState({ fetching: false, error: true });
    }
  }

  render() {
    const { isOpen, toggleRegister } = this.props;
    return (
      <Card className="card-user">
        <Modal isOpen={isOpen} toggle={toggleRegister}>
          <ModalHeader toggle={toggleRegister}>Register</ModalHeader>
          <ModalBody>
            <CardBody>
              <form onKeyPress={event => { if (event.key === 'Enter') return this.onSubmitRegister() }}>
                <FormInputs
                  ncols={["col-md-6", "col-md-6"]}
                  properties={[
                    {
                      label: "First Name",
                      inputProps: {
                        type: "text",
                        placeholder: "First Name",
                        name: "name",
                        onChange: this.handleChange
                      }
                    },
                    {
                      label: "Last Name",
                      inputProps: {
                        type: "text",
                        placeholder: "Last Name",
                        name: 'surname',
                        onChange: this.handleChange
                      }
                    }
                  ]}
                />
                <FormGroup>
                  <Label>Email</Label>
                  <div>
                    <Input
                      type="email"
                      name="email"
                      id="exampleEmail"
                      placeholder="example@email.com"
                      onChange={this.handleChange}
                      invalid={this.state.error}
                    />{this.state.error &&
                      <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Wrong email</FormFeedback>
                    }
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for="selectRole">Role</Label>
                  {/* FIXME: hardcoded */}
                  <Input type="select" name="role" id="selectRole" onChange={this.handleChange}>
                    <option hidden></option>
                    <option>Patient</option>
                    <option>Doctor</option>
                    <option>Researcher</option>
                  </Input>
                </FormGroup>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Company",
                      inputProps: {
                        type: "text",
                        placeholder: "Company name",
                        name: 'company',
                        onChange: this.handleChange
                      }
                    }
                  ]}
                />
                <Row>
                  <div className="update ml-auto mr-auto">
                    {!this.state.fetching
                      ? <Button
                        color="primary"
                        round
                        onClick={this.toggleInfoModal}
                      >
                        Register</Button>
                      : <Button
                        color="grey"
                        round
                        disabled
                      >
                        <img className='spinner' src={require('../../assets/img/spinner.gif')} alt="spinner" />
                      </Button>
                    }
                  </div>
                </Row>
              </form>
            </CardBody>
          </ModalBody>
        </Modal>
        <InfoModal isOpen={this.state.infoModal} toggle={this.toggleInfoModal} message={"User registration feature disabled in demo version"} />
      </Card>
    );
  }
}