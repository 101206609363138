import Dashboard from "views/Dashboard/Dashboard.jsx";
// import TableList from "views/TableList/TableList.jsx";
// import Icons from "views/Icons/Icons.jsx";
// import Typography from "views/Typography/Typography.jsx";
import Trials from "../views/Trials/Trials";
import Contracts from "../views/Contracts/Contracts";
import PatientDashboard from "views/PatientDashboard/PatientDashboard.jsx"
import PatientData from "views/PatientData/PatientData.jsx"
import MedicalData from "views/MedicalData/MedicalData.jsx"
import AdminDashboard from "views/AdminDashboard/AdminDashboard.jsx"
import ResearcherDashboard from "views/ResearcherDashboard/ResearcherDashboard.jsx"

// import Maps from "views/Maps/Maps.jsx";

const allowAll = ['doctor', 'patient', 'researcher', 'admin'];
const allowDoctor = ['doctor'];
const allowPatient = ['patient'];
const allowAdmin = ['admin'];
const allowResearcher = ['researcher'];

var dashRoutes = [
  {
    roles: allowDoctor,
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard
  },
  {
    roles: allowAdmin,
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: AdminDashboard
  },
  {
    roles: allowPatient,
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: PatientDashboard
  },
  {
    roles: allowResearcher,
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: ResearcherDashboard
  },
  {
    roles: allowAll,
    path: "/trials",
    name: "Trials",
    icon: "fas fa-notes-medical",
    component: Trials
  },
  {
    roles: allowAdmin.concat(allowDoctor).concat(allowPatient),
    path: "/contracts",
    name: "Contracts",
    icon: "fa fa-handshake",
    component: Contracts
  },
  {
    roles: allowPatient,
    path: "/patient_data",
    name: "Medical data",
    icon: "fas fa-medkit",
    component: PatientData
  },
  {
    roles: allowDoctor,
    path: "/medical_data",
    name: "Medical data",
    icon: "fas fa-medkit",
    component: MedicalData
  },
  // {
  //   roles: allowAll,
  //   path: "/user-page",
  //   name: "User Profile",
  //   icon: "nc-icon nc-single-02",
  //   component: UserPage
  // },
  // {
  //   roles: allowAdmin,
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "nc-icon nc-diamond",
  //   component: Icons
  // },
  // { path: "/maps", name: "Maps", icon: "nc-icon nc-pin-3", component: Maps },
  // {
  //   roles: ['doctor'],
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications
  // },
  // {
  //   roles: ['admin'],
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-caps-small",
  //   component: Typography
  // },
  // {
  //   pro: true,
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "nc-icon nc-spaceship"
  // },

  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];
export default dashRoutes;
