const dashboard24HoursPerformanceChart = {
  data: (count) => {
    return {
      labels: Object.keys(count),
      datasets: [
        {
          label: 'Trials',
          borderColor: "#6bd098",
          backgroundColor: 'transparent',
          pointRadius: 3,
          pointHoverRadius: 3,
          borderWidth: 3,
          data: getDataArray(count, 'trials'),
          pointStyle: 'circle',
        },
        {
          label: 'Contracts',
          borderColor: "#fcc468",
          backgroundColor: 'transparent',
          pointRadius: 3,
          pointHoverRadius: 3,
          borderWidth: 3,
          data: getDataArray(count, 'contracts'),
          pointStyle: 'rect',
        },
        {
          label: 'System',
          borderColor: "#51CACF",
          backgroundColor: 'transparent',
          pointRadius: 3,
          pointHoverRadius: 3,
          borderWidth: 3,
          data: getDataArray(count, 'system'),
          pointStyle: 'triangle',
        }
      ]
    };
  },
  options: {
    legend: {
      position: 'bottom',
      display: true,
      onHover: function (e) { e.target.style.cursor = 'pointer'; },
      labels: {
        usePointStyle: true,
      }
    },

    hover: {
      onHover: function (e) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = 'pointer';
        else e.target.style.cursor = 'default';
      }
    },

    tooltips: {
      enabled: true
    },

    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5
            //padding: 20
          },
          gridLines: {
            drawBorder: false,
            // zeroLineColor: "#ccc",
            // color: "rgba(255,255,255,0.05)",
            display: true
          }
        }
      ],

      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            // color: "rgba(255,255,255,0.1)",
            // zeroLineColor: "transparent",
            display: true
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f"
          }
        }
      ]
    }
  }
};

const getDataArray = (count, type) => {
  let values = [];
  Object.keys(count).forEach((time) => {
    values.push(count[time][type]);
  });
  return values;
}

const dashboardTrialStatisticsChart = {
  data: (values) => {
    return {
      labels: ['Created', 'Started', 'Stopped', 'Completed', 'Withdrawn'],
      datasets: [
        {
          label: "Contracts",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ["#31b4b5", "#fcc468", "#ef8157", "#69CF99", "#e3e3e3"],
          borderWidth: 0,
          data: [values.n_created, values.n_started, values.n_stopped, values.n_completed, values.n_withdrawn]
        }
      ]
    };
  },

  options: {
    legend: {
      display: false
    },

    pieceLabel: {
      render: "percentage",
      fontColor: ["white"],
      precision: 2
    },

    tooltips: {
      enabled: true
    },

    scales: {
      yAxes: [
        {
          ticks: {
            display: false
          },
          gridLines: {
            drawBorder: false,
            zeroLineColor: "transparent",
            color: "rgba(255,255,255,0.05)"
          }
        }
      ],

      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(255,255,255,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            display: false
          }
        }
      ]
    }
  }
};

const dashboardNASDAQChart = {
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    datasets: [
      {
        label: 'trials',
        data: [0, 19, 15, 20, 30, 40, 40, 50, 25, 30, 50, 70],
        fill: false,
        borderColor: "#fbc658",
        backgroundColor: "transparent",
        pointBorderColor: "#fbc658",
        pointRadius: 4,
        pointHoverRadius: 4,
        pointBorderWidth: 8
      },
      {
        label: 'participants',
        data: [0, 5, 10, 12, 20, 27, 30, 34, 42, 45, 55, 63],
        fill: false,
        borderColor: "#51CACF",
        backgroundColor: "transparent",
        pointBorderColor: "#51CACF",
        pointRadius: 4,
        pointHoverRadius: 4,
        pointBorderWidth: 8
      }
    ]
  },
  options: {
    legend: {
      display: false,
      position: "top"
    }
  }
};

let bioDataset = {
  data: (dataset, color) => {
    return {
      labels: Object.keys(dataset).map(key => Number(key) + 1), // to start from 1
      datasets: [
        {
          label: 'Dataset',
          borderColor: getColor(color),
          backgroundColor: 'transparent',
          pointRadius: 3,
          pointHoverRadius: 3,
          borderWidth: 3,
          data: dataset,
          pointStyle: 'circle',
        },
      ]
    };
  },
  options: {
    legend: {
      position: 'bottom',
      display: false,
      onHover: function (e) { e.target.style.cursor = 'pointer'; },
      labels: {
        usePointStyle: true,
      }
    },

    hover: {
      onHover: function (e) {
        var point = this.getElementAtEvent(e);
        if (point.length) e.target.style.cursor = 'pointer';
        else e.target.style.cursor = 'default';
      }
    },

    tooltips: {
      enabled: true
    },

    scales: {
      yAxes: [
        {
          ticks: {
            fontColor: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5
            //padding: 20
          },
          gridLines: {
            drawBorder: false,
            // zeroLineColor: "#ccc",
            // color: "rgba(255,255,255,0.05)",
            display: true
          }
        }
      ],

      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            // color: "rgba(255,255,255,0.1)",
            // zeroLineColor: "transparent",
            display: true
          },
          ticks: {
            padding: 20,
            fontColor: "#9f9f9f"
          }
        }
      ]
    }
  }
};

const getColor = (name) => {
  switch (name) {
    case 'green': return "#6bd098";
    case 'azure': return "#51CACF";
    case 'yellow': return "#fcc468";
    case 'red': return "#F07E5D";
    case 'blue': return "#007CFA";
    case 'grey': return "#939392";
    default: return "#51CACF";
  }
}

const radar = {
  data: (radarData) => {
    return {
      labels: radarData.labels,
      datasets: [{
        data: radarData.values,
        borderColor: getColor(radarData.color),
        backgroundColor: getColor(radarData.color) + `40`, // add AA to hexadecimal representation to get the transparency #RRGGBBAA (A = alpha)
      }]
    }
  },
  options: {
    legend: false
  }
}

module.exports = {
  dashboard24HoursPerformanceChart,
  dashboardTrialStatisticsChart,
  dashboardNASDAQChart,
  bioDataset,
  radar
};

