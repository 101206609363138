import React from "react";
// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";

import Header from "components/Header/Header.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
// import Footer from "components/Footer/Footer.jsx";

import dashboardRoutes from "routes/dashboard.jsx";

// Events/notifications
import NotificationAlert from "react-notification-alert";

import './Dashboard.css'

// var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
    }
    this.notify = this.notify.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

   componentDidMount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.refs.mainPanel);
    //   document.body.classList.toggle("perfect-scrollbar-on");
    // }
    this.props.socket.onmessage = (msg) => {
      this.notify(msg.data); //FIXME: maybe in this way it doesn't generate error...
    }
  }

  componentWillUnmount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps.destroy();
    //   document.body.classList.toggle("perfect-scrollbar-on");
    // }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  }

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  }

  // to handle notifications
  onDismiss() { }
  notify(event) {
    var options = {};
    options = {
      place: 'bl',
      message: (
        <div>
          <div className="bold">
            {event}
          </div>
        </div>
      ),
      type: 'primary',
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7 //use -1 to set "infinite"
    };
    this.refs.notificationAlert.notificationAlert(options);
    this.props.addEvent(event);
  }

  render() {
    const { user } = this.props;
    return (
      <div className="wrapper">
        <NotificationAlert ref="notificationAlert" />
        <Sidebar
          {...this.props}
          routes={dashboardRoutes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          role={user.role}
        />
        <div className="main-panel" ref="mainPanel">
          <Header {...this.props} />
          <Switch>
            {dashboardRoutes.map((prop, key) => {
              if (prop.pro) {
                return null;
              }
              if (prop.redirect) {
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              }
              if (prop.roles.includes(user.role)) {
                const PropComponent = prop.component; //NB: To pass variables as components it is necessary to name them with capital letter!
                return (
                  <Route path={prop.path} render={() => (<PropComponent {...this.props} />)} key={key} />
                );
              }
            })}
          </Switch>
          {/* FIXME: adjust Footer... */}
          {/* <Footer fluid/>  */}
        </div>
        {/* <FixedPlugin
            bgColor={this.state.backgroundColor}
            activeColor={this.state.activeColor}
            handleActiveClick={this.handleActiveClick}
            handleBgClick={this.handleBgClick}
          /> */}
      </div>
    );
  }
}

export default Dashboard;
