import React from 'react';
import { Card, CardHeader, CardTitle, CardBody, CardFooter, Row, Col } from 'reactstrap'
import { Radar } from 'react-chartjs-2'
import { radar } from 'variables/charts.jsx'
import Stats from "components/Stats/Stats.jsx";

class TrialSummary extends React.Component {
  render() {
    const { radarData, participants } = this.props;
    return (
      <Card>
        <CardHeader>
          <CardTitle><h4>Trial summary</h4></CardTitle>
        </CardHeader>
        <CardBody>
          <Col xs={12}>
            <Card className="card-stats card-plain">
              <CardBody>
                <Row>
                  <Col xs={5} md={4}>
                    <div className="icon-big text-center">
                      <i className="fa fa-users text-primary" />
                    </div>
                  </Col>
                  <Col xs={7} md={8}>
                    <div className="numbers">
                      <p className="card-category">Participants</p>
                      <CardTitle tag="p">
                      {participants}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <hr />
            <Card className="card-stats card-plain">
              <CardTitle><h5>Race</h5></CardTitle>
              <CardBody>
                <Radar data={radar.data(radarData.race)} options={radar.options} />
              </CardBody>
            </Card>
            <hr />
            <Card className="card-stats card-plain">
              <CardTitle><h5>Mood</h5></CardTitle>
              <CardBody>
                <Radar data={radar.data(radarData.mood)} options={radar.options} />
              </CardBody>
            </Card>
            <hr />
            <Card className="card-stats card-plain">
              <CardTitle><h5>Activities</h5></CardTitle>
              <CardBody>
                <Radar data={radar.data(radarData.activities)} options={radar.options} />
              </CardBody>
            </Card>
          </Col>
        </CardBody>
        <CardFooter>
          <hr />
          <Stats>
            {[
              {
                i: "fas fa-sync-alt",
                t: "Update"
              }
            ]}
          </Stats>
        </CardFooter>
      </Card>
    )
  }
}

export default TrialSummary;