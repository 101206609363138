import React from 'react';
import Public from './layouts/Public/Public';
import Dashboard from './layouts/Dashboard/Dashboard';
import Loading from './components/Loading/Loading';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import * as api from './api/api'

const NETWORK_NAME = 'scodes';

class App extends React.Component {

  initialState = {
    loading: false,
    logged: false,
    user: {
      name: '',
      surname: '',
      email: 'doctor@scodes.ch',
      cardName: 'admin@scodes',
      role: 'doctor',
    },
    data: {},
    events: [],
    socket: null,
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  }

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  logIn() {
    this.setState({
      logged: !this.state.logged
    })
  }

  logOut() {
    const { cookies } = this.props;
    // send to server info on last logout of the user and close websocket 
    this.state.socket.send(JSON.stringify({
      event: 'logout',
      user: this.state.user.email,
      time: new Date(),
    }));
    this.state.socket.close();
    cookies.remove('local_state');
    //reset navigation history
    this.props.history.push('/');
    this.setState(this.initialState);
  }

  toggleLoading = () => {
    this.setState({ loading: !this.state.loading })
  }

  loadUser = async (data) => {
    const { email, profile, $class } = data;
    const last_update = await api.getLastLogout(email);
    const events = await api.getAllEvents(this.state.user.cardName, last_update);
    const socket = new WebSocket(`${api.SOCKET_PROTOCOL}:${api.SERVER}:${api.SERVER_PORT}`);
    const state = {
      logged: true,
      user: {
        name: (profile !== undefined) && profile.name,
        surname: (profile !== undefined) && profile.surname,
        email: (profile !== undefined) && email,
        cardName: `${email.split('@')[0]}@scodes`,
        role: $class.split('.')[4].toLowerCase(),
      },
      events: events,
      socket: socket
    }
    await this.setState(state);
    await api.listenEvents(this.state.user.cardName);
  }

  // save state to local storage while the user is logged in
  saveStateToCookies() {
    const { cookies } = this.props;
    cookies.set('local_state', this.state);
    this.state.socket.close();
  }

  async loadStateFromCookies() {
    const { cookies } = this.props;
    const state = cookies.get('local_state');
    if (state) {
      state.socket = new WebSocket(`${api.SOCKET_PROTOCOL}:${api.SERVER}:${api.SERVER_PORT}`);
      await api.listenEvents(state.user.cardName);
      this.setState(state);
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.loadStateFromCookies();
    this.setState({ loading: false });
    window.addEventListener(
      "beforeunload",
      this.saveStateToCookies.bind(this)
    );
  }

  componentWillUnmount() {
    this.state.socket.close();
    window.removeEventListener(
      "beforeunload",
      this.saveStateToCookies.bind(this)
    );
    this.saveStateToCookies();
  }

  addEvent(message) {
    let events = this.state.events;
    events.push({ message: message });
    this.setState({ events: events });
  }

  render() {
    if (!this.state.loading) {
      return this.state.logged
        ? <Dashboard
          {...this.props}
          logIn={this.logIn.bind(this)}
          logOut={this.logOut.bind(this)}
          toggleLoading={this.toggleLoading.bind(this)}
          user={this.state.user}
          events={this.state.events}
          socket={this.state.socket}
          addEvent={this.addEvent.bind(this)}
        />
        : <Public
          {...this.props}
          toggleLoading={this.toggleLoading.bind(this)}
          logIn={this.logIn.bind(this)}
          loadUser={this.loadUser.bind(this)}
          // loadData={this.loadData.bind(this)}
          netName={NETWORK_NAME} />
    } else {
      return (
        <Loading />
      )
    }
  }
}
export default withCookies(App);