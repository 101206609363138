import React from "react";
import { Label, Input, Card, CardHeader, CardBody, CardTitle, Row, Col, Tooltip, FormFeedback, FormGroup } from "reactstrap";

import InfoModal from "components/InfoModal/InfoModal.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import * as api from '../../api/api.js'
import * as utils from '../../utils/helpers.js'

// import './PatientData.css'

// Events/notifications
import NotificationAlert from "react-notification-alert";

class PatientData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      error: false,
      infoModal: false,
      trials: [],
      record_id: '',
      data: {
        trial_id: '',
        heart_rate: '',
        pressure: '',
        temperature: '',
        glycemia: '',
        sleep: '',
        exercise: '',
      }
    }
    this.notify = this.notify.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.toggleInfoModal = this.toggleInfoModal.bind(this);
  }

  toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  toggleInfoModal() {
    this.setState({
      infoModal: !this.state.infoModal
    });
  }

  reset = () => {
    this.setState({
      error: false,
      data: {
        trial_id: '',
        heart_rate: '',
        pressure: '',
        temperature: '',
        glycemia: '',
        sleep: '',
        exercise: '',
      }
    })
  }

  handleChange = (e) => {
    let { data } = this.state;
    data[e.target.name] = e.target.value;
    this.setState({ data });
  }

  handleSubmit = () => {
    if (this.checkFields() === true) {
      let data = [
        {
          record_id: this.state.record_id,
          trial_id: this.state.data.trial_id,
          name: this.props.user.name,
          lastname: this.props.user.surname,
          email: this.props.user.email,
          heart_rate: this.state.data.heart_rate,
          pressure: this.state.data.pressure,
          temperature: this.state.data.temperature,
          glycemia: this.state.data.glycemia,
          sleep: this.state.data.sleep,
          exercise: this.state.data.exercise,
          medical_data_complete: '2',
        },
      ];
      api.addRecords(data);
      this.notify('Data successfully submitted!');
      this.reset();
      this.updateData();
    }
  }

  async componentDidMount() {
    this.updateData();
  }

  updateData = async () => {
    // set next redcap record id (kind of auto incrementing)
    const record_id = await api.generateId();
    const contracts = await api.getAssets(this.props.user.cardName, 'Contract');
    const trials = this.getAvailableTrials(contracts);
    this.setState({
      record_id: record_id,
      trials: trials
    });
  }

  getAvailableTrials(contracts) {
    let trials = [];
    for (let contract of contracts) {
      if (contract.status === "ACCEPTED") {
        trials.push(contract.trial.split('#')[1]); //trim full resource name
      }
    }
    return trials;
  }

  // to handle notifications
  onDismiss() { }
  notify(event) {
    var options = {};
    options = {
      place: 'bl',
      message: (
        <div>
          <div className="bold">
            {event}
          </div>
        </div>
      ),
      type: 'primary',
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7 //use -1 to set "infinite"
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  loadData() {
    const { heart_rate, glycemia, pressure, exercise, sleep, temperature } = utils.generateRndBiodata();
    let data = {
      ...this.state.data,
      heart_rate: heart_rate,
      glycemia: glycemia,
      pressure: pressure,
      exercise: exercise,
      sleep: sleep,
      temperature: temperature,
    }
    this.setState({ data });
  }

  checkFields() {
    const { data } = this.state;
    for (let key in data) {
      if (data[key] === '' || !this.inRange(key, data[key])) {
        this.setState({ error: true });
        return false;
      }
    }
    return true;
  }

  inRange(field, value) {
    switch (field) {
      case 'heart_rate':
        return value > 30 && value < 130;
      case 'glycemia':
        return value > 50 && value < 140;
      case 'pressure':
        return value > 50 && value < 150;
      case 'temperature':
        return value > 35 && value < 42;
      case 'sleep':
        return value >= 0 && value < 24 && value !== '';
      case 'exercise':
        return value > 0 && value < 3000;
      default: return true;
    }
  }

  render() {
    const { user } = this.props;
    const { trials } = this.state;
    return (
      <div className="content">
        <NotificationAlert ref="notificationAlert" />
        <Row>
          <Col>
            <Card className="card-user">
              <CardHeader>
                <CardTitle>Send medical data to REDCap</CardTitle>
              </CardHeader>
              <CardBody>
                <form>
                  <FormGroup row>
                    <Col xs={6} className="pr-3 pl-3">
                      <Label>Trial</Label>
                      <Input value={this.state.data.trial_id} type="select" name="trial_id" invalid={this.state.error && !this.state.data.trial_id} onChange={this.handleChange}>
                        <option hidden></option>
                        {trials.map((el, index) => {
                          return <option key={index}>{el}</option>
                        })}
                      </Input>
                      {this.state.error &&
                        <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                      }
                    </Col>
                    <Col xs={6} className="pr-3 pl-3">
                      <Label>Email</Label>
                      <Input type="email" name="email" value={user.email} disabled>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col xs={6} className="pr-3 pl-3">
                      <Label>First Name</Label>
                      <Input type="text" name="name" value={user.name} disabled>
                      </Input>
                    </Col>
                    <Col xs={6} className="pr-3 pl-3">
                      <Label>Last Name</Label>
                      <Input type="email" name="surname" value={user.surname} disabled>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={4} className="pr-3 pl-3">
                      <Label>Heart Rate [bpm]</Label>
                      <Input
                        name="heart_rate"
                        type="number"
                        onChange={this.handleChange}
                        value={this.state.data.heart_rate}
                        invalid={this.state.error && !this.inRange('heart_rate', this.state.data.heart_rate)}
                      />
                      {this.state.error &&
                        <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                      }
                    </Col>
                    <Col sm={4} className="pr-3 pl-3">
                      <Label>Pressure [mg/dl]</Label>
                      <Input
                        name="pressure"
                        type="number"
                        onChange={this.handleChange}
                        value={this.state.data.pressure}
                        invalid={this.state.error && !this.inRange('pressure', this.state.data.pressure)}
                      />
                      {this.state.error &&
                        <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                      }
                    </Col>
                    <Col sm={4} className="pr-3 pl-3">
                      <Label>Temperature [C°]</Label>
                      <Input
                        name="temperature"
                        type="number"
                        onChange={this.handleChange}
                        value={this.state.data.temperature}
                        invalid={this.state.error && !this.inRange('temperature', this.state.data.temperature)}
                      />
                      {this.state.error &&
                        <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                      }
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm={4} className="pr-3 pl-3">
                      <Label>Glycemia [mg/dl]</Label>
                      <Input
                        name="glycemia"
                        type="number"
                        onChange={this.handleChange}
                        value={this.state.data.glycemia}
                        invalid={this.state.error && !this.inRange('glycemia', this.state.data.glycemia)}
                      />
                      {this.state.error &&
                        <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                      }
                    </Col>
                    <Col sm={4} className="pr-3 pl-3">
                      <Label>Sleep [h]</Label>
                      <Input
                        name="sleep"
                        type="number"
                        onChange={this.handleChange}
                        value={this.state.data.sleep}
                        invalid={this.state.error && !this.inRange('sleep', this.state.data.sleep)}
                      />
                      {this.state.error &&
                        <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                      }
                    </Col>
                    <Col sm={4} className="pr-3 pl-3">
                      <Label>Exercise [kcal]</Label>
                      <Input
                        name="exercise"
                        type="number"
                        onChange={this.handleChange}
                        value={this.state.data.exercise}
                        invalid={this.state.error && !this.inRange('exercise', this.state.data.exercise)}
                      />
                      {this.state.error &&
                        <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                      }
                    </Col>
                  </FormGroup>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button id="load" onClick={() => this.loadData()}>Load</Button>
                      <Tooltip
                        // hideArrow
                        delay={{ show: 1000, hide: 100 }}
                        placement="bottom"
                        target="load"
                        isOpen={this.state.tooltipOpen}
                        toggle={this.toggleTooltip}>
                        Load data from sensors
                      </Tooltip>
                      <Button onClick={this.reset}>Reset</Button>
                      <Button color="primary" onClick={this.toggleInfoModal}>Send</Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <InfoModal isOpen={this.state.infoModal} toggle={this.toggleInfoModal} message={"Uploading data to REDCap has been disabled for demo version"} />
      </div>
    );
  }
}

export default PatientData;
