import React from "react";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
// For the chart
import BioDataset from "components/BioDataset/BioDataset.jsx"
import TrialSummary from "components/TrialSummary/TrialSummary.jsx"

import * as api from '../../api/api.js'
import * as utils from '../../utils/helpers'
import Loading from "../../components/Loading/Loading";

class ResearcherDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      selected: '',
      trials_ids: [],
      datasets: {},
      radarData: {
        race: {
          color: 'azure',
          labels: ['American native', 'Asian', 'Pacific', 'Black', 'White', 'Mixed', 'Unknown'],
          values: [],
        },
        mood: {
          color: 'green',
          labels: ['Very sad', 'Sad', 'Indifferent', 'Happy', 'Very happy'],
          values: [],
        },
        activities: {
          color: 'yellow',
          labels: ['Gym', 'Aerobics', 'Eat out', 'Drink'],
          values: [],
        }
      }
    }
  }

  onChange = (e) => {
    this.setState({ selected: e.target.value });
    this.generateRadarData(e.target.value);
  }

  generateRadarData = (selected) => {
    let { radarData, datasets } = this.state;
    let max = datasets[selected].participants;
    Object.keys(radarData).forEach(key => {
      radarData[key]['values'] = utils.generateRndValuesArray(radarData[key]['labels'].length, max);
    });
    this.setState({ radarData });
  }

  componentDidMount = async () => {
    this.setState({ fetching: true });
    const { cardName, email } = this.props.user;
    const observed = await api.getObserved(cardName, email);
    const datasets = await api.getAcceptedData(observed.trials, observed.participants);
    this.setState({ fetching: false, trials_ids: observed.trials, datasets: datasets });
  }

  render() {
    const { datasets, selected } = this.state;
    const dataset = datasets[selected];
    if (!this.state.fetching) {
      return (
        <div className="content">
          {/* <Container> */}
            <Row className="pb-3">
              <Col xs={12}>
                <Form onChange={this.onChange}>
                  <FormGroup>
                    <Label for="trialSelection" className="centered"><h4>Select trial overview</h4></Label>
                    <Input id="trialSelection" type="select">
                      <option hidden></option>
                      {this.state.trials_ids.map((id, index) => { return <option key={index}>{id}</option> })}
                    </Input>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} lg={4}>
                {this.state.selected &&
                  <TrialSummary radarData={this.state.radarData} participants={dataset.participants}/>
                }
              </Col>
              <Col xs={12} md={6} lg={8}>
                {this.state.selected &&
                  <BioDataset dataset={dataset}/>
                }
              </Col>
            </Row>
          {/* </Container> */}
        </div>
      );
    }
    else return <Loading />
  }
}

export default ResearcherDashboard;