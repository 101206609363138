import React from 'react';
import './Loading.css';

class Loading extends React.Component {
    render() {
        return (
            <div>
                <div className="loading">
                    <img src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                </div>
            </div>
        )
    }
}

export default Loading;