import React from 'react'
import {
  FormFeedback,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';

import Button from '../../components/CustomButton/CustomButton'

import * as api from '../../api/api.js'

const initialState = {
  fetching: false,
  error: false,
  signInEmail: '',
  signInPassword: ''
}


export default class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      error: false,
      signInEmail: '',
      signInPassword: ''
    }
  }

  reset = () => {
    this.setState(initialState);
  }

  onEmailChange = (event) => {
    this.setState({ signInEmail: event.target.value });
    this.setState({ signInPassword: event.target.value.split('@')[0] });
  }

  onPasswordChange = (event) => {
    this.setState({ signInPassword: event.target.value });
  }

  onSubmitSignIn = async () => {
    this.setState({ fetching: true });
    let res = await api.signIn(this.state.signInEmail, this.state.signInPassword);
    if (res) {
      await this.props.loadUser(res);
      //this.setState({ fetching: false, error: false });
    } else {
      this.setState({ fetching: false, error: true });
    }
  }

  render() {
    const { isOpen, toggleSignin } = this.props;
    return (
      <Card>
        <Modal isOpen={isOpen} toggle={toggleSignin} onClosed={this.reset}>
          <ModalHeader toggle={toggleSignin}>Sign In</ModalHeader>
          <ModalBody>
            <CardBody>
              <Container className="App">
                <Form className="form" onKeyPress={event => { if (event.key === 'Enter') return this.onSubmitSignIn() }}>
                  <Col>
                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                        type="email"
                        name="email"
                        id="exampleEmail"
                        placeholder="example@email.com"
                        invalid={this.state.error}
                        onChange={this.onEmailChange}
                      />
                      {this.state.error &&
                        <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}User does not exist!</FormFeedback>
                      }
                      <FormText>Your username is most likely your email.</FormText>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="examplePassword">Password</Label>
                      {/* I set password field automatically to the cardname (i.e. email without last domain part ".ch, .com" ecc.) */}
                      <Input
                        disabled
                        type="password"
                        name="password"
                        id="examplePassword"
                        placeholder="Password"
                        value={this.state.signInEmail.split('@')[0]}
                        onChange={this.onPasswordChange}
                      />
                    </FormGroup>
                  </Col>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      {!this.state.fetching
                        ? <Button
                          color="primary"
                          round
                          onClick={this.onSubmitSignIn}
                        >Sign In</Button>
                        : <Button
                          color="grey"
                          round
                          disabled
                        >
                          <img className='spinner' src={require('../../assets/img/spinner.gif')} alt="spinner" />
                        </Button>
                      }
                    </div>
                  </Row>
                </Form>
              </Container>
            </CardBody>
          </ModalBody>
        </Modal>
      </Card>
    );
  }
} 