import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import TableData from '../TableData/TableData';
import Button from '../../components/CustomButton/CustomButton';
import { thead } from "variables/general";
import NewTrial from "../NewTrial/NewTrial";
import Loading from "../../components/Loading/Loading"
import NoContent from "../../components/NoContent/NoContent"

import './Trials.css'

import * as api from '../../api/api'

export default class Trials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      isOpen: false,
      dropdownOpen: false,
      trial_modal: false,
      trials: [],
      table_data: [],
      modal_data: {},
      supervisors: [],
      observers: [],
    }
  }

  async componentDidMount() {
    await this.updateData()
  }

  updateData = async () => {
    this.setState({ fetching: true });
    const trials = await api.getAssets(this.props.user.cardName, 'Trial');
    const supervisors = await api.getParticipants(this.props.user.cardName, 'Doctor');
    const observers = supervisors.concat(await api.getParticipants(this.props.user.cardName, 'Researcher')).sort((a, b) => {
      return a.email < b.email ? -1 : 1;
    });
    const data = this.setTableData(trials);
    this.setState({
      fetching: false,
      isOpen: false,
      dropdownOpen: false,
      trial_modal: false,
      table_data: data,
      trials: trials,
      filtered: trials,
      supervisors: supervisors,
      observers: observers,
    });
  }

  filterEntries(e) {
    const { trials } = this.state;
    let filtered = trials
    if (e.target.name !== 'all') {
      filtered = trials.filter(trial => trial.status === e.target.name.toUpperCase());
    }
    let t_data = this.setTableData(filtered);
    this.setState({ table_data: t_data, filtered: filtered });
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  toggleTrial() {
    this.setState({
      trial_modal: !this.state.trial_modal,
    });
  }

  setTrialModalData(entry) {
    this.setState({
      modal_data: entry//.data
    })
  }

  setTableData = (trials) => {
    const t_data = [];
    for (let trial of trials) {
      const supervisors = [];
      trial.supervisors.forEach(supervisor => {
        supervisors.push(supervisor.split('#')[1]); //FIXME: not so elegant, but it depends on the way hyperledger stores relationships
      });
      t_data.push({
        data: {
          trialId: trial.trialId,
          supervisors: supervisors,
          description: trial.description,
          duration: trial.duration,
          status: trial.status,
        }
      });
    }
    return t_data;
  }

  render() {
    if (!this.state.fetching) {
      if (this.state.trials.length > 0) {
        return (
          <div className="content">
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader className="grid"> {/*  d-none d-sm-block d-md-block d-lg-block d-xl-block */}
                    <Row>
                      <Col md={10}>
                        <CardTitle tag="h4">Proposed Trials</CardTitle>
                      </Col>
                      <Col className="centered">
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown.bind(this)}>
                          <DropdownToggle size="sm" color="primary" caret>
                            Show
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem header>Status</DropdownItem>
                            <DropdownItem name="all" onClick={this.filterEntries.bind(this)}>All</DropdownItem>
                            <DropdownItem name="created" onClick={this.filterEntries.bind(this)}>Created</DropdownItem>
                            <DropdownItem name="started" onClick={this.filterEntries.bind(this)}>Started</DropdownItem>
                            <DropdownItem name="stopped" onClick={this.filterEntries.bind(this)}>Stopped</DropdownItem>
                            <DropdownItem name="completed" onClick={this.filterEntries.bind(this)}>Completed</DropdownItem>
                            <DropdownItem name="withdrawn" onClick={this.filterEntries.bind(this)}>Withdrawn</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* FIXME: add propType "responsive" to table in order to show scrollbars OR edit css to show scrollbars at specific amount of items! */}
                    <Table hover responsive>
                      <thead className="text-primary">
                        <tr>
                          {thead.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.table_data.map((prop, key) => {
                          return (
                            <tr
                              key={key}
                              onClick={() => {
                                this.setTrialModalData(this.state.filtered[key]); // Needed because otherwise when the modal is closed it doesn't have the state anymore!!! --> ERROR
                                this.toggleTrial();
                              }
                              }
                            // className={prop.className} //can use to add color to row
                            >
                              {Object.keys(prop.data).map((key, index) => { // changed to iterate through object keys
                                if (key === "supervisors") {
                                  return <td key={index} style={{ listStyle: "none"}}>
                                    {prop.data[key].map((el, i) => {
                                    return (
                                      <li key={`${index}.${i}`}>{el}</li> // Needed to set unique key -.-
                                    )
                                  })}
                                  </td>;
                        } else {
                                  return <td key={index}>{prop.data[key]}</td>;
                      }
                    })}
                            </tr>
                      );
                    })}
                      </tbody>
                    </Table>
                  <TableData
                    isOpen={this.state.trial_modal}
                    toggleTrial={this.toggleTrial.bind(this)}
                    data={this.state.modal_data}
                    update={this.updateData.bind(this)}
                    user={this.props.user}>
                  </TableData>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {
          this.props.user.role === 'doctor' &&
          <div >
            <div className="btn-fixed">
              <Button
                fab icon round
                className="add-fab"
                color="primary"
                onClick={() => this.toggle()}
              >
                <i className="fas fa-plus" />
              </Button>
            </div>
            <NewTrial
              isOpen={this.state.isOpen}
              toggle={this.toggle.bind(this)}
              update={this.updateData.bind(this)}
              user={this.props.user}
              supervisors={this.state.supervisors}
              observers={this.state.observers}
            />
          </div>
        }
          </div >
        )
      } else return (
        <div>
          {this.props.user.role === 'doctor' &&
            <div >
              <div className="btn-fixed">
                <Button
                  fab icon round
                  className="add-fab"
                  color="primary"
                  onClick={() => this.toggle()}
                >
                  <i className="fas fa-plus" />
                </Button>
              </div>
              <NewTrial
                isOpen={this.state.isOpen}
                toggle={this.toggle.bind(this)}
                update={this.updateData.bind(this)}
                user={this.props.user}
                supervisors={this.state.supervisors}
                observers={this.state.observers}
              />
            </div>
          }
          <NoContent />
        </div>
      )
    } else return <Loading />
  }
}