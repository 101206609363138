import React from 'react'
import {
  Form,
  Col,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from 'reactstrap'
import ErrorModal from '../../components/ErrorModal/ErrorModal'
import './TableData.css'
import TrialFormatter from '../../components/TrialFormatter/TrialFormatter'

import * as api from '../../api/api'

export default class TableData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      type: 'trial',
      action: '',
      error_modal: false,
      error_message: '',
    }
  }

  toggleError() {
    this.setState(prevState => ({
      error_modal: !prevState.error_modal
    }))
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value.toLowerCase() });
  }

  handleSubmit = async () => {
    this.setState({ fetching: true });
    const res = await api.updateAssetStatus(this.props.user.cardName, this.state.type, this.state.action, this.props.data.trialId);
    this.setState({ fetching: false });
    if (res.status === 200) {
      this.props.update();
    } else {
      const err_msg = await res.text();
      this.setState({ error_message: err_msg });
      this.toggleError();
    }
  }

  getDate() {
    return new Date().toLocaleDateString();
  }

  render() {
    const { isOpen, toggleTrial, data } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggleTrial}
      >
        <ModalHeader toggle={toggleTrial}>Overview</ModalHeader>
        <ModalBody>
          <TrialFormatter data={data} />
          {
            this.props.user.role === 'doctor' &&
            <section className="pt-4">
              <div>
                <h6>Action</h6>
                <Form className="form">
                  <Col>
                    <FormGroup>
                      <Input
                        type="select"
                        name="action"
                        onChange={this.handleChange}
                      >
                        <option hidden></option>
                        {api.TRIAL_ACTIONS.map((el, index) => {
                          return <option key={index}>{el}</option>
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                </Form>
              </div>
            </section>
          }
        </ModalBody>
        {this.props.user.role === 'doctor' &&
          <ModalFooter>
            {!this.state.fetching
              ? <div>
                <Button color="success" onClick={this.handleSubmit}>Accept</Button>{' '}
                <Button color="secondary" onClick={toggleTrial}>Cancel</Button>
              </div>
              : <div className="update ml-auto mr-auto"><Spinner color="secondary" /></div>
            }
          </ModalFooter>
        }
        <ErrorModal isOpen={this.state.error_modal} toggle={this.toggleError.bind(this)} message={this.state.error_message} />
      </Modal>
    );
  }
}
