const getRndInInterval = (min, max) => {
  return Math.round(Math.random() * (max - min) + min);
}

const generateRndBiodata = () => {
  return {
    heart_rate: getRndInInterval(40, 120),
    pressure: getRndInInterval(60, 140),
    temperature: getRndInInterval(35, 41),
    glycemia: getRndInInterval(60, 130),
    sleep: getRndInInterval(0, 12),
    exercise: getRndInInterval(200, 1000)
  }
}

const generateRndValuesArray = (length, max) => {
  let array = [];
  while (length > 0) {
    array.push(getRndInInterval(0, max/length));
    length--;
  }
  return array;
}

export {
  generateRndBiodata,
  generateRndValuesArray
}