import React from 'react';
import './UsersTable.css';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table
} from 'reactstrap'

export default class UsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  componentDidMount() {
    const data = this.setTableData(this.props.users);
    this.setState({
      data: data,
    });
  }

  setTableData(users) {
    const t_data = [];
    for (let user of users) {
      t_data.push({
        name: ((user.profile && user.profile.name) || 'n/a'),
        surname: ((user.profile && user.profile.surname) || 'n/a'),
        email: user.email,
        company: ((user.profile && user.profile.company) || 'n/a')
      })
    }
    return t_data;
  }

  render() {
    const { title, thead } = this.props;
    return (
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col md={10}>
                  <CardTitle tag="h4">{title}</CardTitle>
                  {/* </Col>
                  <Col>
                    <Col className="centered">
                      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown.bind(this)}>
                        <DropdownToggle size="sm" color="primary" caret>
                          Filter
                          </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem header>Status</DropdownItem>
                          <DropdownItem name="all" onClick={this.filterEntries.bind(this)}>All</DropdownItem>
                          <DropdownItem name="issued" onClick={this.filterEntries.bind(this)}>Issued</DropdownItem>
                          <DropdownItem name="accepted" onClick={this.filterEntries.bind(this)}>Accepted</DropdownItem>
                          <DropdownItem name="revoked" onClick={this.filterEntries.bind(this)}>Revoked</DropdownItem>
                          <DropdownItem name="concluded" onClick={this.filterEntries.bind(this)}>Concluded</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Col>*/}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Table hover responsive >
                <thead className="text-primary">
                  <tr>
                    {thead.map((prop, key) => {
                      return <th key={key}>{prop}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map((prop, key) => {
                    return (
                      <tr
                        key={key}
                      >
                        {thead.map((key, index) => { // changed to iterate through object keys NB: temporary change for sponsors!
                          if(key === "Sponsor") return <td key={index}>{prop["company"]}</td>; 
                          return <td key={index}>{prop[key.toLowerCase()]}</td>;
                        }
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}