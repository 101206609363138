import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col
} from "reactstrap";
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
// function that returns a color based on an interval of numbers

import Stats from "components/Stats/Stats.jsx";

import {
  dashboardTrialStatisticsChart,
  dashboardNASDAQChart
} from "variables/charts.jsx";

import './Dashboard.css'

import * as api from '../../api/api.js'

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      trialData: {},
      contractData: {},
      patientData: {},
      accepted: 0,
      revoked: 0,
      started: 0,
      completed: 0,
      stopped: 0,
      withdrawn: 0,
      created: 0,
    }
  }

  async componentDidMount() {
    this.setState({ fetching: true });
    const patientData = await api.getParticipants(this.props.user.cardName, 'Patient');
    const trialData = await api.getAssets(this.props.user.cardName, 'Trial');
    const contractData = await api.getAssets(this.props.user.cardName, 'Contract');
    this.countTrialsByStatus(trialData);
    this.countContractsByStatus(contractData);
    this.setState({
      fetching: false,
      trialData: trialData,
      contractData: contractData,
      patientData: patientData,
    });
  }

  // Moved logic to count trials and contracts on client side (less queries)
  countTrialsByStatus(trials) {
    for (let trial of trials) {
      const field = trial.status.toLowerCase();
      this.setState({ [field]: this.state[field] += 1 });
    };
  }

  countContractsByStatus(contracts) {
    for (let contract of contracts) {
      const field = contract.status.toLowerCase();
      this.setState({ [field]: this.state[field] += 1 });
    };
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={5} md={4}>
                    <div className="icon-big text-center">
                      <i className="fas fa-notes-medical text-warning" />
                    </div>
                  </Col>
                  <Col xs={7} md={8}>
                    <div className="numbers">
                      <p className="card-category">Trials</p>
                      <CardTitle tag="p">{
                        this.state.fetching
                          ? <img className='small' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                          : this.state.trialData.length || '-'
                      }</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Stats>
                  {[
                    {
                      i: "fas fa-sync-alt",
                      t: "Update Now"
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={5} md={4}>
                    <div className="icon-big text-center">
                      <i className="fa fa-handshake text-success" />
                    </div>
                  </Col>
                  <Col xs={7} md={8}>
                    <div className="numbers">
                      <p className="card-category">Contracts (Active)</p>
                      <CardTitle tag="p">{
                        this.state.fetching
                          ? <img className='small' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                          : `${this.state.contractData.length} (${this.state.accepted})` || '-'
                      }</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Stats>
                  {[
                    {
                      i: "far fa-calendar",
                      t: "Last day"
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={5} md={4}>
                    <div className="icon-big text-center">
                      <i className="fa fa-users text-primary" />
                    </div>
                  </Col>
                  <Col xs={7} md={8}>
                    <div className="numbers">
                      <p className="card-category">Participants</p>
                      <CardTitle tag="p">{
                        this.state.fetching
                          ? <img className='small' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                          : this.state.patientData.length || '-'
                      }</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Stats>
                  {[
                    {
                      i: "far fa-clock",
                      t: "In the last hour"
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={3}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={5} md={4}>
                    <div className="icon-big text-center">
                      <i className="fa fa-exclamation-circle text-danger" />
                    </div>
                  </Col>
                  <Col xs={7} md={8}>
                    <div className="numbers">
                      <p className="card-category">Revoked</p>
                      <CardTitle tag="p">{
                        this.state.fetching
                          ? <img className='small' src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" />
                          : this.state.revoked || '-'
                      }</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Stats>
                  {[
                    {
                      i: "fas fa-sync-alt",
                      t: "Update now"
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={4}>
            <Card>
              <CardHeader>
                <CardTitle>Trials Status</CardTitle>
                <p className="card-category">Summary</p>
              </CardHeader>
              <CardBody>
                {this.state.fetching
                  ? <div className="pie"><img className="small" src={require('../../assets/img/logo-color-animated.svg')} alt="loading-logo" /></div>
                  : <Pie
                    data={dashboardTrialStatisticsChart.data({
                      n_started: this.state.started,
                      n_stopped: this.state.stopped,
                      n_completed: this.state.completed,
                      n_withdrawn: this.state.withdrawn,
                      n_created: this.state.created
                    })}
                    options={dashboardTrialStatisticsChart.options}
                  />
                }
              </CardBody>
              <CardFooter>
                <div className="legend">
                  <small><i className="fa fa-circle text-primary" /> Created{" "}</small>
                  <small><i className="fa fa-circle text-warning" /> Started{" "}</small>
                  <small><i className="fa fa-circle text-danger" /> Stopped{" "}</small>
                  <small><i className="fa fa-circle text-success" /> Completed{" "}</small>
                  <small><i className="fa fa-circle text-gray" /> Withdrawn</small>
                </div>
                <hr />
                <Stats>
                  {[
                    {
                      i: "fas fa-medkit",
                      t: " Number of trials created"
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </Col>
          <Col xs={12} sm={12} md={8}>
            <Card className="card-chart">
              <CardHeader>
                <CardTitle>Statistics</CardTitle>
                <p className="card-category">Number of trials and participants in {new Date().getUTCFullYear()}</p>
              </CardHeader>
              <CardBody>
                <Line
                  data={dashboardNASDAQChart.data}
                  options={dashboardNASDAQChart.options}
                  width={400}
                  height={100}
                />
              </CardBody>
              <CardFooter>
                <div className="chart-legend">
                  <i className="fa fa-circle text-info" /> Participants{" "}
                  <i className="fa fa-circle text-warning" /> Trials
                </div>
                <hr />
                <Stats>
                  {[
                    {
                      i: "fas fa-check",
                      t: " Data information certified"
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
