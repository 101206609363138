import React from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Collapse,
  Row,
  Col,
} from 'reactstrap'
import Button from '../CustomButton/CustomButton'
import BioData from '../BioData/BioData'
import './PatientCard.css'

export default class PatientCard extends React.Component {
  constructor(props) {
    super(props);
    this.toggleData = this.toggleData.bind(this);
    this.onEntering = this.onEntering.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.state = {
      collapse: false,
      animate: false,
    }
  }

  toggleData() {
    this.setState({ collapse: !this.state.collapse });
  }

  onEntering() {
    this.setState({ animate: true })
  }

  onExiting() {
    this.setState({ animate: false })
  }

  render() {
    const { data } = this.props
    return (
      <Card>
        <CardBody>
          <Row>
            <Col xs={8}>
              <CardTitle tag="h3">{data.name} {data.surname}</CardTitle>
              <CardSubtitle>
              <i className="fas fa-envelope pr-3 pl-3 text-primary"></i>{data.email}
              <i className="fas fa-medkit pr-3 pl-3 text-primary"></i>{data.trial}
              </CardSubtitle>
            </Col>
            <Col className="text-right" xs={4}>
              <Button icon color="primary" round className={(this.state.animate && 'rotate') || ''} onClick={this.toggleData}><i className='fas fa-plus'/></Button>
            </Col>
          </Row>
          <Collapse
              isOpen={this.state.collapse}
              onEntering={this.onEntering}
              onExiting={this.onExiting}
            >
            <BioData plain data={data.bio}/>
          </Collapse>
        </CardBody>
      </Card>
    )
  }
}