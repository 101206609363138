import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import Button from '../../components/CustomButton/CustomButton';
import { chead } from "variables/general";
import Loading from "../../components/Loading/Loading"
import NewContract from "../NewContract/NewContract"
import RevokeAlert from "../RevokeAlert/RevokeAlert"
import NoContent from "../../components/NoContent/NoContent"

import './Contracts.css'

import * as api from '../../api/api.js'

export default class Contracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      contract_modal: false,
      alert_modal: false,
      dropdownOpen: false,
      table_data: [],
      trials: [],
      contracts: [],
      patients: []
    }
  }

  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  toggleAlertModal = () => {
    this.setState({ alert_modal: !this.state.alert_modal })
  }

  toggleContractModal = () => {
    this.setState({ contract_modal: !this.state.contract_modal });
  }

  componentDidMount = async () => {
    await this.updateData();
  }

  updateData = async () => {
    this.setState({ fetching: true });
    const contracts = await api.getAssets(this.props.user.cardName, 'Contract');
    const trials = await api.getAssets(this.props.user.cardName, 'Trial');
    const patients = await api.getParticipants(this.props.user.cardName, 'Patient');
    const data = this.setTableData(contracts);
    this.setState({
      fetching: false,
      contract_modal: false,
      contractData: {},
      trialData: {},
      table_data: data,
      trials: trials,
      contracts: contracts,
      patients: patients
    });
  }

  filterEntries(e) {
    const { contracts } = this.state;
    let filtered = contracts
    if (e.target.name !== 'all') {
      filtered = contracts.filter(contract => contract.status === e.target.name.toUpperCase());
    }
    let t_data = this.setTableData(filtered);
    this.setState({ table_data: t_data });
  }

  setTableData = (contracts) => {
    const t_data = [];
    for (let contract of contracts) {
      t_data.push({
        data: {
          contractId: contract.contractId.split('#')[0],
          demander: contract.demander.split('#')[1],
          recipient: contract.recipient.split('#')[1],
          trial: contract.trial.split('#')[1],
          status: contract.status,
        }
      });
    }
    return t_data;
  }

  setContractModalData(entry) {
    this.setState({
      contract_data: entry,
    });
  }

  setTrialData = (trialId) => {
    const id = trialId.split('#')[1]
    const trial = this.state.trials.find(trial => trial.trialId === id);
    this.setState({
      trialData: trial,
    })
  }

  render() {
    const { role } = this.props.user;
    if (!this.state.fetching) {
      if (this.state.contracts.length > 0) {
        return (
          <div className="content">
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md={10}>
                        <CardTitle tag="h4">Contracts</CardTitle>
                      </Col>
                      <Col>
                        <Col className="centered">
                          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown.bind(this)}>
                            <DropdownToggle size="sm" color="primary" caret>
                              Show
                          </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem header>Status</DropdownItem>
                              <DropdownItem name="all" onClick={this.filterEntries.bind(this)}>All</DropdownItem>
                              <DropdownItem name="issued" onClick={this.filterEntries.bind(this)}>Issued</DropdownItem>
                              <DropdownItem name="accepted" onClick={this.filterEntries.bind(this)}>Accepted</DropdownItem>
                              <DropdownItem name="revoked" onClick={this.filterEntries.bind(this)}>Revoked</DropdownItem>
                              <DropdownItem name="concluded" onClick={this.filterEntries.bind(this)}>Concluded</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </Col>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Table hover responsive className={role !== 'patient' ? 'no-pointer' : ''}>
                      <thead className="text-primary">
                        <tr>
                          {chead.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.table_data.map((prop, key) => {
                          return (
                            <tr
                              key={key}
                              onClick={() => { // Needed because otherwise when the modal is closed it doesn't have the state anymore!!! --> ERROR
                                this.setContractModalData(this.state.contracts[key]);
                                this.setTrialData(this.state.contracts[key].trial);
                                this.toggleAlertModal();
                              }
                              }
                              className={prop.data.status === 'ISSUED' ? 'table-info' : ''} //can use to add color to row
                            >
                              {Object.keys(prop.data).map((key, index) => { // changed to iterate through object keys
                                return <td key={index}>{prop.data[key]}</td>;
                              }
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    {role === 'patient' &&
                      <RevokeAlert
                        isOpen={this.state.alert_modal}
                        toggleAlert={this.toggleAlertModal.bind(this)}
                        user={this.props.user}
                        contractData={this.state.contract_data}
                        trialData={this.state.trialData}
                        update={this.updateData.bind(this)}
                      />
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {role === 'doctor' &&
              <div >
                <div className="btn-fixed">
                  <Button
                    fab icon round
                    className="add-fab"
                    color="primary"
                    onClick={() => this.toggleContractModal()}
                  >
                    <i className="fas fa-plus" />
                  </Button>
                </div>
                <NewContract
                  isOpen={this.state.contract_modal}
                  toggle={this.toggleContractModal.bind(this)}
                  update={this.updateData.bind(this)}
                  user={this.props.user}
                  patients={this.state.patients}
                  trials={this.state.trials}
                />
              </div>
            }
          </div>
        )
      } else return (
        <div>
          {role === 'doctor' &&
            <div >
              <div className="btn-fixed">
                <Button
                  fab icon round
                  className="add-fab"
                  color="primary"
                  onClick={() => this.toggleContractModal()}
                >
                  <i className="fas fa-plus" />
                </Button>
              </div>
              <NewContract
                isOpen={this.state.contract_modal}
                toggle={this.toggleContractModal.bind(this)}
                update={this.updateData.bind(this)}
                user={this.props.user}
                patients={this.state.patients}
                trials={this.state.trials}
              />
            </div>
          }
          <NoContent />
        </div>
      )
    } else {
      return <Loading />
    }
  }
}
