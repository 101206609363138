import React from "react";
import { FormGroup, Label, Input, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, FormFeedback } from "reactstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import * as api from '../../api/api.js'

import './NewTrial.css';

const initialState = {
  fetching: false,
  error: false,
  trialId: '',
  duration: '',
  description: '',
  supervisors: [],
  phases: [{
    description: '',
    start: '',
    end: '',
    observers: [],
    data: [{
      type: '',
      description: '',
      purpose: '',
    }],
  }
  ]
}

export default class NewTrial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      error: false,
      trialId: '',
      duration: '',
      description: '',
      supervisors: [],
      phases: [{
        description: '',
        start: '',
        end: '',
        observers: [],
        data: [{
          type: '',
          description: '',
          purpose: '',
        }],
      }
      ]
    };
  }

  reset = () => {
    this.setState(initialState);
  }

  addPhase = (event) => {
    let phases = [...this.state.phases];
    phases.push({
      description: '',
      start: '',
      end: '',
      observers: [],
      data: [{
        type: '',
        description: '',
        purpose: '',
      }],
    });
    this.setState({ phases })
  }

  addData = (e) => {
    e.preventDefault();
    let phases = [...this.state.phases];
    phases[Number(e.target.id)].data.push({
      type: '',
      description: '',
      purpose: '',
    });
    this.setState((prevState) => ({
      phases
    }));
  }

  //NB: Black magic to catch all changes on the form and update the respective fields in the state
  handleChange = (e) => {
    if (e.target.className.includes('phase')) {
      let phases = [...this.state.phases];
      if (e.target.className.includes('data')) {
        let ids = e.target.id.split('.');
        let ph_id = ids[0];
        let data_id = ids[1];
        phases[ph_id].data[data_id][e.target.name] = e.target.value;
        this.setState({ phases })
      } else {
        if (e.target.name === 'observers') {
          phases[e.target.id][e.target.name] = Array.from(e.target.selectedOptions, (item) => item.value)
        } else {
          phases[e.target.id][e.target.name] = e.target.value
        }
        this.setState({ phases })
      }
    }
    else if (e.target.name === 'supervisors') {
      this.setState({ [e.target.name]: Array.from(e.target.selectedOptions, (item) => item.value) })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  submitTrialCreatedTransaction = async () => {
    if (this.checkFields()) {
      this.setState({ fetching: true });
      await api.createTrial(this.props.user.cardName, this.state);
      this.setState({ fetching: false });
      this.props.update();
    }
  }

  // FIXME: maybe there is a better way...
  checkFields() {
    for (let key in this.state) {
      if (key !== 'fetching' && key !== 'error') {
        if (key === 'phases') {
          let phases = this.state[key];
          for (let phase of phases) {
            for (let key in phase) {
              if (key === 'data') {
                let data = phase[key];
                for (let entry of data) {
                  for (let key in entry) {
                    if (entry[key] === '') {
                      this.setState({ error: true });
                      return false;
                    }
                  }
                }
              }
              let prop = phase[key];
              if ((Array.isArray(prop) && prop.length === 0) || prop === '') {
                this.setState({ error: true });
                return false;
              }
            }
          }
        }
        else {
          let prop = this.state[key];
          if ((Array.isArray(prop) && prop.length === 0) || prop === '') {
            this.setState({ error: true });
            return false;
          }
        }
      }
    }
    return true;
  }

  render() {
    const { isOpen, toggle, supervisors, observers } = this.props;
    const { phases } = this.state;
    return (
      <div className="content">
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          onClosed={this.reset}
          size="lg"
        >
          <ModalHeader toggle={toggle}>New Trial</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12}>
                <form onChange={this.handleChange}>
                  <FormGroup>
                    <Label>Trial Id</Label>
                    <Input placeholder="TRIAL_###" type="text" name="trialId" invalid={this.state.error && !this.state.trialId} />
                    {this.state.error &&
                      <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                    }
                  </FormGroup>
                  <FormGroup>
                    <Label>Duration</Label>
                    <Input type="text" name="duration" invalid={this.state.error && !this.state.duration} />
                    {this.state.error &&
                      <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                    }
                  </FormGroup>
                  <FormGroup>
                    <Label>Description</Label>
                    <Input className="form-resizable" placeholder="Describe the purpose of the trial" type="textarea" name="description" invalid={this.state.error && !this.state.description} />
                    {this.state.error &&
                      <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                    }
                  </FormGroup>
                  <FormGroup>
                    <Label for="selectSupervisors">Select Supervisors</Label>
                    <Input type="select" name="supervisors" id="selectSupervisors" multiple invalid={this.state.error && !(this.state.supervisors.length > 0)}>
                      {supervisors.map((el, index) => {
                        return <option key={index}>{el.email}</option>
                      })}
                    </Input>
                    {this.state.error &&
                      <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                    }
                  </FormGroup>
                  {
                    phases.map((val, index) => {
                      let phase_id = `Phase-${index + 1}`;
                      return (
                        <div key={index}>
                          <hr></hr>
                          <h5>{phase_id}</h5>
                          <FormGroup>
                            <Label>Description</Label>
                            <Input id={index} className="phase" placeholder="Describe the phase" type="text" name="description" invalid={this.state.error && !this.state.phases[index].description} />
                            {this.state.error &&
                              <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                            }
                          </FormGroup>
                          <Row>
                            <Col xs={6}>
                              <FormGroup>
                                <Label>Start</Label>
                                <Input id={index} className="phase" type="date" name="start" invalid={this.state.error && !this.state.phases[index].start} />
                                {this.state.error &&
                                  <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                                }
                              </FormGroup>
                            </Col>
                            <Col xs={6}>
                              <FormGroup>
                                <Label>End</Label>
                                <Input id={index} className="phase" type="date" name="end" invalid={this.state.error && !this.state.phases[index].end} />
                                {this.state.error &&
                                  <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                                }
                              </FormGroup>
                            </Col>
                          </Row>
                          <FormGroup>
                            <Label for="selectObservers">Select Observers</Label>
                            <Input type="select" name="observers" id={index} className="phase" multiple invalid={this.state.error && !(this.state.phases[index].observers.length > 0)}>
                              {observers.map((el, index) => {
                                return <option key={index}>{el.email}</option>
                              })}
                            </Input>
                            {this.state.error &&
                              <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                            }
                          </FormGroup>
                          <Row className="centered">
                            <Col md={3}>
                              <Label for="data-type">Data Type</Label>
                            </Col>
                            <Col md={3}>
                              <Label for="data-purpose">Data Purpose</Label>
                            </Col>
                            <Col md={3}>
                              <Label for="data-description">Data Description</Label>
                            </Col>
                            <Col md={3}>
                              {/* NB: added id to <i/> as a secure measure: if the event is captured on the icon it still works 
                                NB2: it can be solved with the class no-event (see NewTrial.css)*/}
                              <Button color="success" size="sm" round outline icon id={index} onClick={this.addData}><i className="fas fa-plus no-event" /></Button>
                            </Col>
                          </Row>
                          {
                            phases[index].data.map((val, idx) => {
                              return (
                                <Row form key={`${index}.${idx}`}>
                                  <Col md={3}>
                                    <FormGroup>
                                      {/* <Label for="data">Data Type</Label> */}
                                      <Input type="select" name="type" id={`${index}.${idx}`} className="phase data" invalid={this.state.error && !this.state.phases[index].data[idx].type}>
                                        <option hidden></option>
                                        {api.DATA_TYPES.map((el, index) => {
                                          return <option key={index}>{el}</option>
                                        })
                                        }
                                      </Input>
                                      {this.state.error &&
                                        <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                                      }
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      {/* <Label for="data">Data Purpose</Label> */}
                                      <Input type="select" name="purpose" id={`${index}.${idx}`} className="phase data" invalid={this.state.error && !this.state.phases[index].data[idx].purpose}>
                                        <option hidden></option>
                                        {api.PURPOSES.map((el, index) => {
                                          return <option key={index}>{el}</option>
                                        })
                                        }
                                      </Input>
                                      {this.state.error &&
                                        <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                                      }
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Input type="text" name="description" id={`${index}.${idx}`} className="phase data" invalid={this.state.error && !this.state.phases[index].data[idx].description} />
                                      {this.state.error &&
                                        <FormFeedback><i className="fas fa-exclamation-triangle"></i>{' '}Invalid value!</FormFeedback>
                                      }
                                    </FormGroup>
                                  </Col>
                                </Row>
                              );
                            })
                          }
                        </div>
                      );
                    })
                  }
                  <Row>
                    <hr />
                    <div className="update ml-auto mr-auto">
                      <Button color="success" round outline icon onClick={this.addPhase}><i className="fas fa-plus" /></Button>
                    </div>
                  </Row>
                </form>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            {this.state.fetching
              ? <Button disabled color="text-gray"><img className='spinner' src={require('../../assets/img/spinner.gif')} alt="spinner" /></Button>
              : <Button color="success" onClick={this.submitTrialCreatedTransaction}>Submit</Button>
            }
            <Button onClick={this.reset}>Reset</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}