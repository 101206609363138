import React from 'react'

import {
  Card,
  CardBody,
  Container,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from 'reactstrap';

import Button from '../../components/CustomButton/CustomButton'

import * as api from '../../api/api'
import TrialFormatter from '../../components/TrialFormatter/TrialFormatter';

export default class RevokeAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
    }
  }

  updateContract = async (event) => {
    this.setState({ fetching: true })
    const { contractData } = this.props;
    if (event.target.name === 'accept') {
      await api.updateAssetStatus(this.props.user.cardName, 'contract', 'accept', contractData.contractId)
    } else {
      await api.updateAssetStatus(this.props.user.cardName, 'contract', 'revoke', contractData.contractId)
    }
    this.setState({ fetching: false })
    this.props.toggleAlert();
    this.props.update()
  }

  render() {
    const { isOpen, toggleAlert, trialData } = this.props;
    return (
      <div className="card-container">
        <Card>
          <Modal isOpen={isOpen} toggle={toggleAlert}>
            <ModalHeader toggle={toggleAlert}>Consent</ModalHeader>
            <ModalBody>
              <TrialFormatter data={trialData} />
              <CardBody>
                <Container className="App">
                  <Form className="form">
                    <Row>
                      <div className="update ml-auto mr-auto">
                        {!this.state.fetching
                          ? <div>
                            <Button name="accept" color="success" onClick={this.updateContract}>Accept</Button>
                            <Button name="revoke" color="danger" onClick={this.updateContract}>Revoke</Button>
                          </div>
                          : <div><Spinner color="secondary" /></div>
                        }
                      </div>
                    </Row>
                  </Form>
                </Container>
              </CardBody>
            </ModalBody>
          </Modal>
        </Card>
      </div>
    );
  }
} 