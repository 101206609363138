import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Container,
} from "reactstrap";
// For the chart
import { Line } from 'react-chartjs-2';
import Stats from "components/Stats/Stats.jsx";
import {
  dashboard24HoursPerformanceChart
} from "variables/charts.jsx";

import UsersTable from "components/UsersTable/UsersTable.jsx"

import './AdminDashboard.css'

import * as api from '../../api/api.js'
import Loading from "../../components/Loading/Loading";

class AdminDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      trialData: {},
      contractData: {},
      doctors: [],
      researchers: [],
      patients: [],
      transactions: [],
      count: {},
    }
  }

  async componentDidMount() {
    this.setState({ fetching: true });
    const patients = await api.getParticipants(this.props.user.cardName, 'Patient');
    const doctors = await api.getParticipants(this.props.user.cardName, 'Doctor');
    const researchers = await api.getParticipants(this.props.user.cardName, 'Researcher');
    const trialData = await api.getAssets(this.props.user.cardName, 'Trial');
    const contractData = await api.getAssets(this.props.user.cardName, 'Contract');
    const transactions = await api.getTransactions(this.props.user.cardName);
    const tx_data = this.setTransactionsData(transactions);
    const count = this.countTxnsByTypeAndTime(transactions);
    this.setState({
      fetching: false,
      trialData: trialData,
      contractData: contractData,
      patients: patients,
      doctors: doctors,
      researchers: researchers,
      transactions: tx_data,
      count: count,
    });
  }

  setTransactionsData(txns) {
    txns = txns.sort((a, b) => {
      return a.transactionTimestamp < b.transactionTimestamp ? 1 : -1; //sort discending
    });
    let tx_data = [];
    for (let txn of txns) {
      tx_data.push({
        type: txn.transactionType.split('.')[4], // retrieve type splitting full resource name
        date: new Date(txn.transactionTimestamp).toLocaleString(),
        invoker: "participantInvoking" in txn ? txn.participantInvoking.split('#')[1] : 'System',
        id: txn.transactionId,
      })
    }
    return tx_data;
  }

  countTxnsByTypeAndTime(txns) {
    txns = txns.sort((a, b) => {
      return a.transactionTimestamp < b.transactionTimestamp ? -1 : 1; //reverse sort (ascending) to make months appear in order
    });
    let count = {};
    for (let txn of txns) {
      // let time = new Date(txn.transactionTimestamp).toDateString().split(' ')[1]; //get string representation of the month
      let time = new Date(txn.transactionTimestamp).getHours(); // divide time by hours
      let name = txn.transactionType.split('.')[4];
      let type = '';
      // group transactions into larger types 
      if (name.includes('Trial')) type = 'trials';
      else if (name.includes('Contract')) type = 'contracts';
      else type = 'system';
      if (count[time]) {
        if (count[time][type]) {
          count[time][type] += 1;
        } else {
          count[time][type] = 1;
        }
      } else {
        count[time] = {};
        count[time][type] = 1;
      }
    }
    return count;
  }

  render() {
    const { transactions } = this.state;
    return (
      <div className="content">
        {!this.state.fetching
          ? <Container>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <CardTitle>Transactions History</CardTitle>
                    <p className="card-category">Hourly operations</p>
                  </CardHeader>
                  <CardBody>
                    <Line
                      data={dashboard24HoursPerformanceChart.data(this.state.count)}
                      options={dashboard24HoursPerformanceChart.options}
                      width={600}
                      height={200}
                    />
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <Stats>
                      {[
                        {
                          i: "fas fa-history",
                          t: `Updated`
                        }
                      ]}
                    </Stats>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md={10}>
                        <CardTitle tag="h4">Transactions</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Table hover responsive >
                      <thead className="text-primary">
                        <tr>
                          <th>Type</th>
                          <th>Date, Time</th>
                          <th>Invoker</th>
                          <th className="text-right" >Transaction Id</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((prop, key) => {
                          return (
                            <tr key={key}>
                              {Object.keys(prop).map((key, index) => {
                                if (index === Object.keys(prop).length - 1)
                                  return (
                                    <td key={index} className="text-right">
                                      {prop[key]}
                                    </td>
                                  ); // changed to iterate through object keys
                                return <td key={index}>{prop[key]}</td>;
                              }
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <UsersTable users={this.state.doctors} title="Investigators" thead={["Email", "Sponsor"]} />
              </Col>
              <Col>
                <UsersTable users={this.state.researchers} title="Researchers" thead={["Email", "Sponsor"]} />
              </Col>
              <Col>
                <UsersTable users={this.state.patients} title="Participants" thead={["Name", "Surname", "Email"]} />
              </Col>
            </Row>
          </Container>
          : <Loading />
        }
      </div>
    );
  }
}

// const fake_data = {
//   "Jan": { "system": 54, "trials": 33, "contracts": 11 },
//   "Feb": { "contracts": 39, "trials": 36, "system": 47 },
//   "Mar": { "contracts": 52, "trials": 38, "system": 60 },
//   "Apr": { "contracts": 61, "trials": 57, "system": 67 },
//   "May": { "contracts": 63, "trials": 80, "system": 71 },
//   "Jun": { "contracts": 75, "trials": 81, "system": 75 },
//   "Jul": { "contracts": 90, "trials": 83, "system": 77 },
//   "Aug": { "contracts": 93, "trials": 90, "system": 80 },
//   "Sep": { "contracts": 102, "trials": 92, "system": 99 },
//   "Oct": { "contracts": 112, "trials": 93, "system": 100 },
//   "Nov": { "contracts": 130, "trials": 100, "system": 118 },
//   "Dic": { "contracts": 138, "trials": 103, "system": 123 },
// }

export default AdminDashboard;

