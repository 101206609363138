import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import './ErrorModal.css';

class ErrorModal extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMsg = this.toggleMsg.bind(this);
    this.onEntered = this.onEntered.bind(this);
    this.onExited = this.onExited.bind(this);
    this.state = { collapse: false, btn: 'more' }
  }

  toggleMsg() {
    this.setState({ collapse: !this.state.collapse });
  }

  onEntered() {
    this.setState({ btn: 'less' });
  }

  onExited() {
    this.setState({ btn: 'more' })
  }

  render() {
    const { isOpen, toggle, message } = this.props;
    return (
      <div>
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle} className="text-danger"><i className="fas fa-exclamation-triangle"></i>{' '}Error</ModalHeader>
          <ModalBody>
            <div className="centered mb-1 pb-1">
              Server responded with an error!
            </div>
            <Collapse
              isOpen={this.state.collapse}
              onEntered={this.onEntered}
              onExited={this.onExited}
            >
              <hr className="light"/>
              <div className="wrap">
                {message}
              </div>
            </Collapse>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toggleMsg}>{this.state.btn}</Button>
            <Button color="secondary" onClick={toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ErrorModal;