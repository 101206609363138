// ##############################
// // // table head data and table body data for Tables view
// #############################

const thead = ["ID", "Supervisors", "Description", "Duration", "Status"];
const chead = ["ID", "Demander", "Recipient", "Trial", "Status"];
const tbody = [
  {
    className: "table-success",
    data: ["Dakota Rice", "Niger", "Oud-Turnhout", "$36,738"]
  },
  {
    className: "",
    data: ["Minerva Hooper", "Curaçao", "Sinaai-Waas", "$23,789"]
  },
  {
    className: "table-info",
    data: ["Sage Rodriguez", "Netherlands", "Baileux", "$56,142"]
  },
  {
    className: "",
    data: ["Philip Chaney", "Korea, South", "Overland Park", "$38,735"]
  },
  {
    className: "table-danger",
    data: ["Doris Greene", "Malawi", "Feldkirchen in Kärnten", "$63,542"]
  },
  {
    className: "",
    data: ["Mason Porter", "Chile", "Gloucester", "$78,615"]
  },
  {
    className: "table-warning",
    data: ["Jon Porter", "Portugal", "Gloucester", "$98,615"]
  }
];

// mock data for clinical trials
const tdata = [
  {
    className: "table-info",
    data: {
      title: "Dakota Rice",
      country: "Niger",
      city: "Oud-Turnhout",
      budget: "$36,738",
    },
    description: {
      name: "Dakota Rice",
      purpose: "Eiusmod officia adipisicing consectetur velit fugiat exercitation est consequat veniam dolor aliqua do. Exercitation cupidatat fugiat officia consectetur culpa esse anim anim ad ullamco dolor sint voluptate aliqua. Ea Lorem cillum duis occaecat deserunt do aliquip ullamco tempor ex.",
      costs: "Sunt aliqua nostrud tempor tempor elit mollit consequat eiusmod. Eu magna dolore laborum pariatur ex proident consequat excepteur culpa Lorem ad consectetur labore. Aliquip incididunt elit cillum labore veniam dolore cillum fugiat sit.",
      risks: "Dolore consectetur tempor duis dolore deserunt laboris reprehenderit incididunt aliquip. Adipisicing non aute velit fugiat ea. Ex aliquip excepteur et ex. Cillum ut aute laborum voluptate ut enim in ea officia eiusmod occaecat. Reprehenderit non laborum cillum et ut ullamco consequat consequat. Ipsum voluptate ullamco cupidatat aliquip Lorem qui reprehenderit voluptate occaecat duis pariatur. Aliquip adipisicing culpa tempor cupidatat reprehenderit.",
      benefits: "Dolore non veniam et esse nisi ex nulla sit consectetur occaecat dolor commodo. Cillum labore proident fugiat sunt anim adipisicing. Ea irure velit enim magna aliqua adipisicing pariatur ex elit mollit. Veniam sint aliqua anim laboris aute incididunt et. Ea pariatur laboris velit nulla. Amet laborum qui est enim nulla ea exercitation proident minim quis id. Aliqua ex laborum proident nulla eiusmod.",
      privacy: "Quis dolor duis mollit culpa est voluptate aliquip duis laborum eu adipisicing. Enim proident id amet eu velit commodo magna elit et ut voluptate. Nostrud cillum exercitation do consequat ea consequat dolor quis irure velit enim officia. Nulla laboris do velit est ullamco nisi est ea duis. Pariatur do consequat et aliquip adipisicing. Dolore velit sint occaecat qui adipisicing aute fugiat commodo sit id amet esse. Non tempor sint incididunt Lorem commodo esse fugiat esse ipsum."
    }
  },
  {
    className: "",
    data: {
      title: "Minerva Hooper",
      country: "Curaçao",
      city: "Sinaai-Waas",
      budget: "$36,738",
    },
    description: {
      name: "Minerva Hooper",
      purpose: "Quis adipisicing id ipsum amet excepteur minim proident dolore qui magna exercitation nulla fugiat. Ad elit aliqua amet incididunt magna veniam duis. Incididunt deserunt nisi ullamco sit do. Commodo veniam ut in culpa qui cupidatat proident proident voluptate officia non pariatur. Incididunt in officia consequat nisi. Duis consectetur mollit ad eu est irure est magna cillum reprehenderit sunt occaecat sit excepteur.",
      costs: "Deserunt duis incididunt aliquip sint adipisicing sint. Id ullamco qui aliqua elit labore sit est est incididunt occaecat nisi elit. Eu dolore ex excepteur do enim. Aliqua ullamco excepteur voluptate est amet incididunt mollit dolor exercitation pariatur non esse aliqua.",
      risks: "Esse aute commodo qui eu. Proident minim fugiat enim veniam tempor ex magna fugiat mollit eu. Quis amet deserunt laborum sint ex aliqua anim sint. Eu sit aliqua anim elit amet duis. Nisi proident velit sunt reprehenderit id nisi ut.",
      benefits: "Lorem cillum est elit elit pariatur exercitation in. Nostrud commodo minim ex nulla. Fugiat sit nostrud qui non duis excepteur nostrud eiusmod adipisicing nostrud magna. Labore aute aliquip proident sunt ad do deserunt elit commodo occaecat nostrud nulla culpa.",
      privacy: "Id est non qui nisi duis. Do consequat consequat velit nisi deserunt nostrud eu consequat. Laboris magna ad dolore velit duis incididunt nulla sit enim deserunt exercitation quis Lorem aliquip. Qui quis qui adipisicing reprehenderit aute."
    }

  },
  {
    className: "table-info",
    data: {
      title: "Mason Porter",
      country: "Niger",
      city: "Oud-Turnhout",
      budget: "$23,789",
    },
    description: {
      name: "Mason Porter",
      purpose: "Nulla aute reprehenderit elit quis nulla anim. Exercitation eu sunt aute occaecat commodo occaecat labore. Anim ex ullamco commodo ullamco eiusmod laboris reprehenderit ullamco culpa nisi eu Lorem nulla qui. Anim proident ullamco minim in nostrud qui duis qui mollit eu amet ad incididunt ullamco.",
      costs: "Ipsum cupidatat ea esse consectetur et culpa sint nisi ad. Aute tempor nostrud incididunt do tempor eu nostrud ut ullamco tempor veniam cupidatat. Commodo veniam ad ut elit labore irure est officia occaecat dolor commodo tempor. Qui consectetur et Lorem ex. Dolore proident eu ad excepteur non ut ullamco.",
      risks: "Id reprehenderit cupidatat exercitation ea est aliqua adipisicing officia voluptate reprehenderit. Esse do dolor occaecat cillum ea amet eiusmod ullamco. Reprehenderit ullamco consectetur deserunt do ad sunt excepteur culpa est fugiat labore irure deserunt elit. Minim in id esse eu pariatur laborum reprehenderit non sint ipsum. Culpa labore officia in laborum. Tempor culpa nostrud fugiat id ea qui nulla quis.",
      benefits: "Dolor sint occaecat cupidatat pariatur enim in tempor ipsum aliqua nostrud velit incididunt pariatur nostrud. Culpa nostrud proident aliqua fugiat est ullamco ut deserunt id veniam. Labore labore elit culpa Lorem exercitation. Consequat laboris minim deserunt laboris adipisicing eiusmod est occaecat exercitation eu culpa occaecat est. Adipisicing Lorem occaecat aliquip elit elit duis aliquip et amet. Deserunt irure commodo fugiat deserunt sint aute enim mollit aliqua aute non est excepteur. Qui velit velit cillum adipisicing ad ipsum ipsum.",
      privacy: "Veniam qui veniam officia sint occaecat ipsum. Dolor occaecat cillum Lorem enim ipsum pariatur ea. Consequat eiusmod proident aliquip officia amet culpa quis minim eu nulla. Anim ipsum reprehenderit do nisi et ut do ut velit aliquip ut magna."
    }
  },
  {
    className: "",
    data: {
      title: "Sage Rodriguez",
      country: "Netherlands",
      city: "Baileux",
      budget: "$56,142",
    },
    description: {
      name: "Sage Rodriguez",
      purpose: "Ut voluptate cillum irure excepteur pariatur amet duis officia esse sint aute. Elit deserunt qui labore ex velit mollit excepteur excepteur irure deserunt dolore ipsum aliqua. Enim et in aute voluptate sint consectetur.",
      costs: "Irure voluptate amet occaecat dolor incididunt proident mollit. Ad cillum esse non sit do enim aute fugiat. Nisi ad dolore sint incididunt laborum est officia quis laboris. In officia minim do laborum quis pariatur magna sunt nulla consectetur aute laborum ex qui. Deserunt do nostrud aliqua consequat labore culpa velit culpa magna laborum ut dolore minim anim.",
      risks: "Pariatur exercitation irure consectetur eiusmod mollit excepteur irure aute eiusmod. Ad sint duis culpa deserunt. Do consequat deserunt irure eu culpa anim ipsum amet veniam occaecat ex fugiat exercitation esse. Ex consectetur nulla non duis proident magna irure proident et tempor reprehenderit ullamco dolore non. Aliquip do consectetur consectetur cillum minim ad consectetur adipisicing laborum Lorem nostrud. Laboris quis incididunt est occaecat labore pariatur aute officia laboris laborum dolore. Eiusmod magna ea laborum enim reprehenderit ipsum minim nulla exercitation ea duis elit adipisicing id.",
      benefits: "Ad Lorem pariatur nostrud in. Occaecat fugiat ipsum minim consectetur dolor reprehenderit non incididunt incididunt excepteur dolor excepteur. Pariatur qui ut culpa mollit mollit aliquip est mollit aliquip. Dolor voluptate qui enim aliqua nisi ipsum eiusmod ea. Pariatur eiusmod laboris eiusmod Lorem nostrud minim enim nulla elit velit.",
      privacy: "Elit do culpa labore anim consectetur proident nisi consequat ipsum. Culpa adipisicing quis laboris ea do velit occaecat ad ipsum id occaecat. Ullamco sunt voluptate anim laborum. Qui sint occaecat non officia mollit fugiat qui exercitation ad reprehenderit."
    }
  },
  {
    className: "table-info",
    data: {
      title: "Philip Chaney",
      country: "Korea, South",
      city: "Overland Park",
      budget: "$38,735",
    },
    description: {
      name: "Philip Chaney",
      purpose: "Nulla ea quis minim consequat laborum excepteur id reprehenderit. Id anim minim elit culpa reprehenderit cupidatat aute deserunt excepteur et occaecat labore quis magna. Veniam elit elit ad occaecat consectetur et.",
      costs: "Sunt quis amet cillum eiusmod sit officia do laboris do eiusmod laboris sint. Esse voluptate culpa et sunt esse commodo non consequat culpa proident nulla sint aliqua. Consequat sit consequat eu fugiat veniam veniam anim ut esse et amet. Consectetur irure labore aliquip voluptate non.",
      risks: "Do irure enim officia officia et. Ullamco ex cillum laboris sint officia veniam voluptate. Officia duis duis eiusmod sit excepteur mollit.",
      benefits: "Quis voluptate ullamco commodo cupidatat ex fugiat et sint cupidatat tempor. Amet ex commodo incididunt cupidatat eu do in exercitation. Fugiat proident amet sit laborum cillum sint aliqua Lorem in culpa proident. Ipsum minim laborum et nisi non. Non incididunt aliqua ad consectetur aliquip. Sunt est enim sunt minim duis nulla aliquip elit ad ut proident esse mollit. Commodo officia ex ad tempor qui nisi mollit incididunt enim non eu culpa.",
      privacy: "Minim nisi esse fugiat proident velit exercitation voluptate minim pariatur. Irure nostrud eiusmod nostrud esse proident. Cupidatat in magna duis fugiat sunt Lorem anim non fugiat adipisicing minim. Reprehenderit est excepteur pariatur quis exercitation deserunt sint adipisicing sit sunt anim enim elit non. Non ipsum excepteur in quis in sit quis aute."
    }
  },{
    className: "table-info",
    data: {
      title: "Philip Chaney",
      country: "Korea, South",
      city: "Overland Park",
      budget: "$38,735",
    },
    description: {
      name: "Philip Chaney",
      purpose: "Nulla ea quis minim consequat laborum excepteur id reprehenderit. Id anim minim elit culpa reprehenderit cupidatat aute deserunt excepteur et occaecat labore quis magna. Veniam elit elit ad occaecat consectetur et.",
      costs: "Sunt quis amet cillum eiusmod sit officia do laboris do eiusmod laboris sint. Esse voluptate culpa et sunt esse commodo non consequat culpa proident nulla sint aliqua. Consequat sit consequat eu fugiat veniam veniam anim ut esse et amet. Consectetur irure labore aliquip voluptate non.",
      risks: "Do irure enim officia officia et. Ullamco ex cillum laboris sint officia veniam voluptate. Officia duis duis eiusmod sit excepteur mollit.",
      benefits: "Quis voluptate ullamco commodo cupidatat ex fugiat et sint cupidatat tempor. Amet ex commodo incididunt cupidatat eu do in exercitation. Fugiat proident amet sit laborum cillum sint aliqua Lorem in culpa proident. Ipsum minim laborum et nisi non. Non incididunt aliqua ad consectetur aliquip. Sunt est enim sunt minim duis nulla aliquip elit ad ut proident esse mollit. Commodo officia ex ad tempor qui nisi mollit incididunt enim non eu culpa.",
      privacy: "Minim nisi esse fugiat proident velit exercitation voluptate minim pariatur. Irure nostrud eiusmod nostrud esse proident. Cupidatat in magna duis fugiat sunt Lorem anim non fugiat adipisicing minim. Reprehenderit est excepteur pariatur quis exercitation deserunt sint adipisicing sit sunt anim enim elit non. Non ipsum excepteur in quis in sit quis aute."
    }
  },{
    className: "table-info",
    data: {
      title: "Philip Chaney",
      country: "Korea, South",
      city: "Overland Park",
      budget: "$38,735",
    },
    description: {
      name: "Philip Chaney",
      purpose: "Nulla ea quis minim consequat laborum excepteur id reprehenderit. Id anim minim elit culpa reprehenderit cupidatat aute deserunt excepteur et occaecat labore quis magna. Veniam elit elit ad occaecat consectetur et.",
      costs: "Sunt quis amet cillum eiusmod sit officia do laboris do eiusmod laboris sint. Esse voluptate culpa et sunt esse commodo non consequat culpa proident nulla sint aliqua. Consequat sit consequat eu fugiat veniam veniam anim ut esse et amet. Consectetur irure labore aliquip voluptate non.",
      risks: "Do irure enim officia officia et. Ullamco ex cillum laboris sint officia veniam voluptate. Officia duis duis eiusmod sit excepteur mollit.",
      benefits: "Quis voluptate ullamco commodo cupidatat ex fugiat et sint cupidatat tempor. Amet ex commodo incididunt cupidatat eu do in exercitation. Fugiat proident amet sit laborum cillum sint aliqua Lorem in culpa proident. Ipsum minim laborum et nisi non. Non incididunt aliqua ad consectetur aliquip. Sunt est enim sunt minim duis nulla aliquip elit ad ut proident esse mollit. Commodo officia ex ad tempor qui nisi mollit incididunt enim non eu culpa.",
      privacy: "Minim nisi esse fugiat proident velit exercitation voluptate minim pariatur. Irure nostrud eiusmod nostrud esse proident. Cupidatat in magna duis fugiat sunt Lorem anim non fugiat adipisicing minim. Reprehenderit est excepteur pariatur quis exercitation deserunt sint adipisicing sit sunt anim enim elit non. Non ipsum excepteur in quis in sit quis aute."
    }
  },{
    className: "table-info",
    data: {
      title: "Philip Chaney",
      country: "Korea, South",
      city: "Overland Park",
      budget: "$38,735",
    },
    description: {
      name: "Philip Chaney",
      purpose: "Nulla ea quis minim consequat laborum excepteur id reprehenderit. Id anim minim elit culpa reprehenderit cupidatat aute deserunt excepteur et occaecat labore quis magna. Veniam elit elit ad occaecat consectetur et.",
      costs: "Sunt quis amet cillum eiusmod sit officia do laboris do eiusmod laboris sint. Esse voluptate culpa et sunt esse commodo non consequat culpa proident nulla sint aliqua. Consequat sit consequat eu fugiat veniam veniam anim ut esse et amet. Consectetur irure labore aliquip voluptate non.",
      risks: "Do irure enim officia officia et. Ullamco ex cillum laboris sint officia veniam voluptate. Officia duis duis eiusmod sit excepteur mollit.",
      benefits: "Quis voluptate ullamco commodo cupidatat ex fugiat et sint cupidatat tempor. Amet ex commodo incididunt cupidatat eu do in exercitation. Fugiat proident amet sit laborum cillum sint aliqua Lorem in culpa proident. Ipsum minim laborum et nisi non. Non incididunt aliqua ad consectetur aliquip. Sunt est enim sunt minim duis nulla aliquip elit ad ut proident esse mollit. Commodo officia ex ad tempor qui nisi mollit incididunt enim non eu culpa.",
      privacy: "Minim nisi esse fugiat proident velit exercitation voluptate minim pariatur. Irure nostrud eiusmod nostrud esse proident. Cupidatat in magna duis fugiat sunt Lorem anim non fugiat adipisicing minim. Reprehenderit est excepteur pariatur quis exercitation deserunt sint adipisicing sit sunt anim enim elit non. Non ipsum excepteur in quis in sit quis aute."
    }
  }
];

// data for <thead> of table in TableList view
// data for <tbody> of table in TableList view
export { thead, chead, tbody, tdata };
