import React from 'react'
import {
  UncontrolledCollapse,
} from 'reactstrap'

export default class TrialFormatter extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <section>
        {Object.keys(data).map((key, index) => {
          if (index > 0) {
            switch (key) {
              case 'supervisors': return (
                <div key={index}>
                  <b>{key.charAt(0).toUpperCase() + key.slice(1)}: </b>
                  {data[key].map((el, i) => {
                    return (
                      <li className='pb-1' key={`${index}.${i}`}>{el.split('#')[1]}</li> // Needed to set unique key -.-
                    )
                  })}
                </div>);
              case 'status': break;
              case 'phases': return (
                <div key={index} >
                  <b>Phases:</b>
                  {data[key].map((phase, i) => {
                    let toggler = `toggler${i}`
                    return (
                      <div key={`${index}.${i}`}>
                        <span className="highlight pointer pt-1" id={toggler}>Phase-{++i}<div className="caret"></div></span>
                        <UncontrolledCollapse toggler={toggler}>
                          <ul>
                            <li><b>Description </b>{phase.description}</li>
                            <li><b>Start </b>{new Date(phase.start).toUTCString()}</li>
                            <li><b>End </b>{new Date(phase.end).toUTCString()}</li>
                            {phase.data.map((entry, idd) => {
                              return (
                                <li key={`${index}.${i}.${idd}`}><b>Dataset {++idd}</b>
                                  <ul>
                                    <li><b>Type </b>{entry.type}</li>
                                    <li><b>Description </b>{entry.description}</li>
                                    <li><b>Purpose </b>{entry.purpose}</li>
                                  </ul>
                                </li>
                              )
                            })}
                            <li><b>Observers</b></li>
                            <ul>
                              {phase.observers.map((entry, idd) => {
                                return (
                                  <li key={`${index}.${i}.${idd}`}>{entry.split('#')[1]}</li>
                                )
                              })}
                            </ul>
                          </ul>
                          <hr className="separator" />
                        </UncontrolledCollapse>
                      </div>
                    );
                  })
                  }
                </div>
              );
              default:
                return (
                  <div className='inline' key={index}>
                    <p><b>{key.charAt(0).toUpperCase() + key.slice(1)}: </b>{data[key]}</p>
                  </div>
                )
            }
          }
        })}
      </section>
    )
  }
}