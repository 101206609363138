import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class InfoModal extends React.Component {
  render() {
    const { isOpen, toggle, message } = this.props;
    return (
      <div>
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle} className="text-primary"><i className="fas fa-info-circle"></i>{' '}Info</ModalHeader>
          <ModalBody>
            <div className="centered mb-1 pb-1">
              {message}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default InfoModal;